import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import BrandLogo from "@/components/BrandLogo.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    mixins: [helperMixin],
    components: { BaseButtonPrimary, BrandLogo, BaseLink },
    emits: ["on-submit"],
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        submitButtonText: { type: String },
        submitButtonLoadingText: { type: String },
        errorMsg: {
            default: "",
        },
        successMsg: {
            default: "",
        },
    },
    mounted() {
        var _a;
        this.setPageTitle(this.trans(((_a = this.currentRoute.meta) === null || _a === void 0 ? void 0 : _a.pageTitle) || "layout.guest_page_title"));
        this.appStore.state.loadingPage = false;
    },
});
