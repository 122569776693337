import { groupInput, twoStateBindValue } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [twoStateBindValue, groupInput],
});
