import { defineComponent } from "vue";
export default defineComponent({
    props: {
        title: {
            type: [String, Number],
            required: false
        },
        description: {
            type: String,
            required: false
        }
    }
});
