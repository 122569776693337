import { reactive, watch } from "vue";
export default class Store {
    constructor() {
        this._state = reactive({
            ...this.states()
        });
    }
    watch(s, cb, options) {
        return watch(() => this._state[s], cb, options);
    }
    get state() {
        return this._state;
    }
}
