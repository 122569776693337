import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import UserCertificatesDownload from "@/components/JobApplicant/UserCertificatesDownload.vue";
export default defineComponent({
    components: {
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        UserCertificatesDownload
    },
    props: {
        certificates: {
            type: Array,
            required: true
        }
    },
    mixins: [helperMixin],
    computed: {
        noDataAvailable() {
            return !this.certificates.length;
        }
    }
});
