import { useRouter } from "@/lib/router";
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        tabs: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            currentTab: null
        };
    },
    mounted() {
        if (this.$route.query.tab) {
            this.currentTab = this.tabs.find(tab => tab.queryString === this.$route.query.tab);
        }
        else {
            this.currentTab = this.tabs.find(tab => !!tab.isDefault);
        }
    },
    methods: {
        tabActiveClass(tab) {
            var _a;
            return ((_a = this.currentTab) === null || _a === void 0 ? void 0 : _a.title) === tab.title ? "is-active" : "";
        },
        async onTabClick(tab) {
            if (this.loading)
                return;
            this.loading = true;
            this.currentTab = null;
            await this.$nextTick();
            this.currentTab = tab;
            this.loading = false;
        }
    },
    watch: {
        currentTab(tab) {
            if (tab) {
                useRouter().replace({
                    query: {
                        ...this.$route.query,
                        tab: tab.queryString || undefined
                    }
                });
            }
        }
    }
});
