import { helperMixin, infinitySrollJobApplicantByStatus } from "@/mixins";
import { defineComponent } from "vue";
import { JobApplicantStatus } from "@/enums";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import InterviewModal from "@/components/JobApplicant/InterviewModal.vue";
import UserCertificatesDownload from "@/components/JobApplicant/UserCertificatesDownload.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import jobApplicantHttp from "@/http/jobApplicant";
import ProfileDetailModal from "@/components/JobApplicant/ProfileDetailModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
export default defineComponent({
    components: {
        TeleportToContentFooter,
        BaseButtonPrimary,
        BaseCheckbox,
        InterviewModal,
        UserCertificatesDownload,
        AvatarImg,
        BaseTable,
        BaseTableColumn,
        BaseTableRow,
        BaseButtonDanger,
        ProfileDetailModal,
        ConfirmModal,
    },
    mixins: [
        helperMixin,
        infinitySrollJobApplicantByStatus([JobApplicantStatus.InProcess]),
    ],
    data() {
        return {
            showProfileDetailModal: false,
            showInterviewModal: false,
            selectedCandidates: [],
        };
    },
    computed: {
        jobApplicantHttp,
    },
    methods: {
        showProfileDetail(jobApplicant) {
            this.selectedRow = jobApplicant;
            this.showProfileDetailModal = true;
        },
        async rejects() {
            this.loading = true;
            if (await this.requestHandler(this.jobApplicantHttp.rejects(this.selectedCandidates.map((candidate) => candidate.id)))) {
                this.loading = false;
                this.resetAction();
            }
        },
        resetAction() {
            this.selectedCandidates = [];
            this.reload();
        },
        addToInterview() {
            this.showInterviewModal = true;
        },
    },
});
