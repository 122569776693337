import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class SubscriptionPlanHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = [];
    }
    baseURL() {
        return "subscription_plan";
    }
    features() {
        return this.request("GET", this.url("features"));
    }
}
const subscriptionPlanHttp = createInstance(SubscriptionPlanHttp);
export default subscriptionPlanHttp;
