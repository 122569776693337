import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import { baseModal, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import jobApplicantHttp from "@/http/jobApplicant";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import UserCertificatesDownload from "@/components/JobApplicant/UserCertificatesDownload.vue";
import AvatarImg from "@/components/AvatarImg.vue";
export default defineComponent({
    components: {
        BaseModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        UserCertificatesDownload,
        AvatarImg,
    },
    mixins: [helperMixin, baseModal],
    emits: ["done"],
    props: {
        state: {
            type: String,
            default: "new schedule",
        },
        candidates: {
            type: Array,
            required: true,
        },
    },
    computed: {
        jobApplicantHttp,
    },
    data() {
        return {
            loading: false,
            validationErrors: {},
        };
    },
    methods: {
        rejectReschedule() { },
        acceptReschedule() { },
        async createSchedules() {
            if (this.loading)
                return;
            this.loading = true;
            if (await this.requestHandler(this.jobApplicantHttp.createSchedules(this.candidates.map((candidate) => ({
                id: candidate.id,
                interview_at: candidate.interview_at,
            }))))) {
                this.loading = false;
                this.value = false;
                this.$emit("done");
                return;
            }
            this.loading = false;
            this.validationErrors = this.jobApplicantHttp.validationErrors;
        },
    },
});
