import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class Chat extends BaseResourceHttp {
    baseURL() {
        return "chat";
    }
    unreadChat(chatId, companyOperatorId) {
        return this.request("POST", this.url(`read/${chatId}/${companyOperatorId}`));
    }
    recentChat(operatorId, params) {
        return this.request("GET", this.url(`recent/${operatorId}`), {
            params
        });
    }
    fetchMessage(chatId, operatorId, params) {
        return this.request("GET", this.url(`message/${chatId}/${operatorId}`), {
            params
        });
    }
    sendMessage(data) {
        return this.request("POST", this.baseURL(), {
            data
        });
    }
    startChat(sailorId, operatorId) {
        return this.request("GET", this.url(`start/${sailorId}/${operatorId}`));
    }
    chooseUser(filter) {
        return this.request("GET", this.url("user"), {
            params: filter,
        });
    }
}
export const chatHttp = createInstance(Chat);
export default chatHttp;
