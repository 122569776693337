import { createInstance } from '@/lib/fn';
import BaseResourceHttp from '@/lib/resourceHttp';
class SailorHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["create", "delete", "update"];
    }
    baseURL() {
        return "sailor";
    }
    paginate(filter) {
        var _a;
        return super.paginate({
            ...filter,
            position_in: (_a = filter === null || filter === void 0 ? void 0 : filter.position_in) === null || _a === void 0 ? void 0 : _a.join(","),
        });
    }
}
export default createInstance(SailorHttp);
