export var UserRole;
(function (UserRole) {
    UserRole["SuperAdmin"] = "super admin";
    UserRole["Admin"] = "admin";
    UserRole["Sailor"] = "sailor";
})(UserRole || (UserRole = {}));
export var RouteNames;
(function (RouteNames) {
    RouteNames["Welcome"] = "Welcome";
    RouteNames["User"] = "User";
    RouteNames["UserCreate"] = "UserCreate";
    RouteNames["VerifyEmail"] = "VerifyEmail";
    RouteNames["Login"] = "Login";
    RouteNames["Register"] = "Register";
    RouteNames["PasswordReset"] = "PasswordReset";
    RouteNames["Dashboard"] = "Dashboard";
    RouteNames["Job"] = "Job";
    RouteNames["JobCreate"] = "JobCreate";
    RouteNames["JobEdit"] = "JobEdit";
    RouteNames["JobApplicant"] = "JobNewApplicant";
    RouteNames["JobApplicantInProcess"] = "JobApplicantInProcess";
    RouteNames["JobApplicantInterview"] = "JobApplicantInterview";
    RouteNames["JobApplicantDetail"] = "JobApplicantDetail";
    // JobApplicantRescheduleRequest = "JobApplicantRescheduleRequest",
    // JobApplicantRejected = "JobApplicantRejected",
    RouteNames["Crew"] = "Crew";
    RouteNames["CrewDetail"] = "CrewDetail";
    RouteNames["Fleet"] = "Fleet";
    RouteNames["FleetCreate"] = "FleetCreate";
    RouteNames["FleetEdit"] = "FleetEdit";
    RouteNames["FleetDetail"] = "FleetDetail";
    RouteNames["Planning"] = "Planning";
    RouteNames["Setting"] = "setting";
    RouteNames["AccountSetting"] = "AccountSetting";
    RouteNames["CompanySetting"] = "CompanySetting";
    RouteNames["Billing"] = "Billing";
    RouteNames["Paywall"] = "Paywall";
    RouteNames["Payment"] = "Payment";
    // superadmin page
    RouteNames["Company"] = "Company";
    RouteNames["CompanyDetail"] = "CompanyDetail";
    RouteNames["Sailor"] = "Sailor";
    RouteNames["SailorCreate"] = "SailorCreate";
    RouteNames["SailorEdit"] = "SailorEdit";
    RouteNames["SailorDetail"] = "SailorDetail";
    RouteNames["Applicant"] = "Applicant";
    RouteNames["News"] = "News";
    RouteNames["NewsCreate"] = "NewsCreate";
    RouteNames["NewsUpdate"] = "NewsUpdate";
    RouteNames["NewsEdit"] = "NewsEdit";
    RouteNames["SubscriptionPlan"] = "SubscriptionPlan";
    RouteNames["SubscriptionPlanCreate"] = "SubscriptionPlanCreate";
    RouteNames["SubscriptionPlanEdit"] = "SubscriptionPlanEdit";
    RouteNames["Identifier"] = "Identifier";
    RouteNames["ChatChooseOperator"] = "chat_operator";
    RouteNames["Chat"] = "chat";
    RouteNames["Qna"] = "Qna";
})(RouteNames || (RouteNames = {}));
export var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["Identifier"] = "_identifier";
    LocalStorageKeys["Token"] = "_token";
    LocalStorageKeys["LastResendEmail"] = "last_resend_email";
})(LocalStorageKeys || (LocalStorageKeys = {}));
export var EventNames;
(function (EventNames) {
    EventNames[EventNames["IncomingApplication"] = 0] = "IncomingApplication";
    EventNames[EventNames["InterviewRespond"] = 1] = "InterviewRespond";
    EventNames[EventNames["RescheduleRespond"] = 2] = "RescheduleRespond";
    EventNames[EventNames["SaveButtonAppClick"] = 3] = "SaveButtonAppClick";
    EventNames[EventNames["CancelButtonAppClick"] = 4] = "CancelButtonAppClick";
    EventNames[EventNames["BaseSelectClick"] = 5] = "BaseSelectClick";
    EventNames[EventNames["FilterButtonClick"] = 6] = "FilterButtonClick";
})(EventNames || (EventNames = {}));
export var SelectionType;
(function (SelectionType) {
    SelectionType["JobHiringNationality"] = "job_hiring_nationality";
    SelectionType["JobPurpose"] = "job_purpose";
    SelectionType["JobRequirement"] = "job_requirement";
    SelectionType["JobPosition"] = "job_position";
    SelectionType["JobContractType"] = "job_contract_type";
    SelectionType["JobSalaryType"] = "job_salary_type";
    SelectionType["JobBenefit"] = "job_benefit";
    SelectionType["Currency"] = "currency";
    SelectionType["FleetComunication"] = "fleet_communication";
    SelectionType["FleetComunicationDetail"] = "fleet_communication_detail";
})(SelectionType || (SelectionType = {}));
export var JobApplicantStatus;
(function (JobApplicantStatus) {
    JobApplicantStatus["Sent"] = "sent";
    JobApplicantStatus["InProcess"] = "in_process";
    JobApplicantStatus["InterviewInvitation"] = "interview_invitation";
    JobApplicantStatus["Interview"] = "interview";
    JobApplicantStatus["InterviewRejected"] = "interview_rejected";
    JobApplicantStatus["RescheduleRequest"] = "reschedule_request";
    JobApplicantStatus["Rescheduled"] = "rescheduled";
    JobApplicantStatus["RescheduleRejected"] = "reschedule_rejected";
    JobApplicantStatus["RecruitmentRequest"] = "recruitment_request";
    JobApplicantStatus["Rejected"] = "rejected";
    JobApplicantStatus["Hired"] = "hired";
})(JobApplicantStatus || (JobApplicantStatus = {}));
export var JobApplicantRescheduleAct;
(function (JobApplicantRescheduleAct) {
    JobApplicantRescheduleAct["Reject"] = "reschedule_reject";
    JobApplicantRescheduleAct["Accept"] = "reschedule_accept";
})(JobApplicantRescheduleAct || (JobApplicantRescheduleAct = {}));
export var JobApplicantInterviewAct;
(function (JobApplicantInterviewAct) {
    JobApplicantInterviewAct["Accept"] = "accept";
    JobApplicantInterviewAct["Reject"] = "reject";
})(JobApplicantInterviewAct || (JobApplicantInterviewAct = {}));
export var Default;
(function (Default) {
    Default[Default["image"] = require("@/assets/default_image.png")] = "image";
})(Default || (Default = {}));
export var ImageLink;
(function (ImageLink) {
    ImageLink["withText"] = "https://placehold.co/600x400?text=Image";
})(ImageLink || (ImageLink = {}));
export var DefaultCondition;
(function (DefaultCondition) {
    DefaultCondition["FleetCommunicationNeedDetail"] = "Tel. No";
})(DefaultCondition || (DefaultCondition = {}));
export var CrewStatus;
(function (CrewStatus) {
    CrewStatus["StandByOnFleet"] = "stand_by_on_fleet";
    CrewStatus["OnBoard"] = "on_board";
    CrewStatus["StandBy"] = "stand_by";
    CrewStatus["Releiver"] = "releiver";
    CrewStatus["Identifier"] = "identifier";
})(CrewStatus || (CrewStatus = {}));
export var Readliness;
(function (Readliness) {
    /**
     * semua sertifikat jauh dari expired.
     */
    Readliness["Good"] = "good";
    /**
     * ada sertifikat yang mendekati expired.
     */
    Readliness["Warning"] = "warning";
})(Readliness || (Readliness = {}));
export var Tonnage;
(function (Tonnage) {
    Tonnage["Gross"] = "groos";
    Tonnage["Net"] = "net";
})(Tonnage || (Tonnage = {}));
export var Gender;
(function (Gender) {
    Gender["Male"] = "male";
    Gender["Female"] = "female";
})(Gender || (Gender = {}));
export var CrewBoardSaveAct;
(function (CrewBoardSaveAct) {
    CrewBoardSaveAct["Replacement"] = "replacement";
    CrewBoardSaveAct["Save"] = "save";
    CrewBoardSaveAct["Renew"] = "renew";
})(CrewBoardSaveAct || (CrewBoardSaveAct = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["HiringRequestAccepted"] = "hiring_request_accepted";
    NotificationType["HiringRequestRejected"] = "hiring_request_rejected";
    NotificationType["IncomingApplication"] = "incoming_application";
})(NotificationType || (NotificationType = {}));
export var FilterChartPeriode;
(function (FilterChartPeriode) {
    FilterChartPeriode["month"] = "month";
    FilterChartPeriode["day"] = "day";
})(FilterChartPeriode || (FilterChartPeriode = {}));
export var CompanySubscriptionStatusEnum;
(function (CompanySubscriptionStatusEnum) {
    CompanySubscriptionStatusEnum["NeedApproval"] = "need_approval";
    CompanySubscriptionStatusEnum["NeedExtended"] = "need_extended";
    CompanySubscriptionStatusEnum["NeedReminder"] = "need_reminder";
    CompanySubscriptionStatusEnum["NonActive"] = "non_active";
    CompanySubscriptionStatusEnum["Active"] = "active";
    CompanySubscriptionStatusEnum["FreeTrial"] = "free_trial";
})(CompanySubscriptionStatusEnum || (CompanySubscriptionStatusEnum = {}));
export var CompanySubscriptionSaveAct;
(function (CompanySubscriptionSaveAct) {
    CompanySubscriptionSaveAct["Approval"] = "approval";
    CompanySubscriptionSaveAct["Extend"] = "extend";
    CompanySubscriptionSaveAct["UpdateExpDate"] = "update_exp_date";
    CompanySubscriptionSaveAct["UpdateDeviceLimit"] = "update_device_limit";
})(CompanySubscriptionSaveAct || (CompanySubscriptionSaveAct = {}));
export var SubscriptionPlanPeriod;
(function (SubscriptionPlanPeriod) {
    SubscriptionPlanPeriod["month"] = "month";
    SubscriptionPlanPeriod["year"] = "year";
})(SubscriptionPlanPeriod || (SubscriptionPlanPeriod = {}));
export var SubscriptionPlanCurrency;
(function (SubscriptionPlanCurrency) {
    SubscriptionPlanCurrency["Dollar"] = "$";
    SubscriptionPlanCurrency["IDR"] = "Rp";
})(SubscriptionPlanCurrency || (SubscriptionPlanCurrency = {}));
export var SubscriptionPaymentStatus;
(function (SubscriptionPaymentStatus) {
    SubscriptionPaymentStatus["Approved"] = "approved";
    SubscriptionPaymentStatus["Waiting"] = "waiting";
    SubscriptionPaymentStatus["Rejected"] = "rejected";
})(SubscriptionPaymentStatus || (SubscriptionPaymentStatus = {}));
export var QnaStatus;
(function (QnaStatus) {
    QnaStatus["NeedApproval"] = "need_approval";
    QnaStatus["Approved"] = "approved";
    QnaStatus["Rejected"] = "rejected";
})(QnaStatus || (QnaStatus = {}));
