<template>
    <div class="loading-page">
        <div class="content">
            <div class="uwu-loader">
                <div class="uwu-loader-icon"></div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
