import AvatarImg from "@/components/AvatarImg.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseToggleSwitch from "@/components/Base/BaseToggleSwitch.vue";
import ApprovalModal from "@/components/Company/ApprovalModal.vue";
import CompanySubscriptionStatus from "@/components/Company/CompanySubscriptionStatus.vue";
import ExtendDateModal from "@/components/Company/ExtendDateModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import { CompanySubscriptionStatusEnum } from "@/enums";
import CompanyHttp from "@/http/company";
import companySubscriptionHttp from "@/http/companySubscription";
import menuAccessHttp from "@/http/menuAccess";
import { helperMixin, infinityScrollTable } from "@/mixins";
import { defineComponent } from "vue";
import ExtendedModal from "@/components/Company/ExtendedModal.vue";
import DeviceLimitInput from "@/components/Company/DeviceLimitInput.vue";
import CompanyExpDate from "@/components/Company/CompanyExpDate.vue";
export default defineComponent({
    components: {
        CompanyExpDate,
        DeviceLimitInput,
        ExtendedModal,
        ApprovalModal,
        ConfirmModal,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        DropdownItem,
        BaseToggleSwitch,
        AvatarImg,
        CompanySubscriptionStatus,
        ExtendDateModal,
    },
    mixins: [
        helperMixin,
        infinityScrollTable(CompanyHttp()),
    ],
    computed: {
        companySubscriptionHttp,
        menuAccessHttp,
    },
    data() {
        return {
            extendDateModal: false,
            extendModal: false,
            approvalModal: false,
            currentMenuAccessSave: null,
            menus: [],
        };
    },
    methods: {
        handleStatusClick(row) {
            this.selectedRow = row;
            if (row.subscription.status == CompanySubscriptionStatusEnum.NeedApproval) {
                this.approvalModal = true;
            }
            else if (row.subscription.status == CompanySubscriptionStatusEnum.NeedExtended) {
                this.extendModal = true;
            }
        },
        async onMounted() {
            this.menus = await this.menuAccessHttp.getMenus();
        },
    },
});
