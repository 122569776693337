import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";
export default createBaseChildrenRecordRaw('fleet', {
    index: {
        name: RouteNames.Fleet,
        component: () => import("@/pages/Admin/Fleet/Index.vue")
    },
    create: {
        name: RouteNames.FleetCreate,
        component: () => import("@/pages/Admin/Fleet/Save.vue"),
    },
    edit: {
        name: RouteNames.FleetEdit,
        component: () => import("@/pages/Admin/Fleet/Save.vue"),
    },
    detail: {
        name: RouteNames.FleetDetail,
        component: () => import("@/pages/Admin/Fleet/Detail.vue"),
    }
});
