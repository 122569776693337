import { infinityScrollTable, helperMixin, filterHelper, positionFilter } from "@/mixins";
import { defineComponent } from "vue";
import sailorHttp from "@/http/sailor";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import companyHttp from "@/http/company";
export default defineComponent({
    components: {
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        AvatarImg,
    },
    props: {
        companyId: {
            required: true
        }
    },
    mixins: [
        helperMixin,
        filterHelper(),
        positionFilter(),
        infinityScrollTable(sailorHttp())
    ],
    computed: {
        companyHttp
    },
    mounted() {
        this.filter.company_id = this.companyId;
    }
});
