import { filterHelper, helperMixin, infinityScrollTable, positionFilter, } from "@/mixins";
import { defineComponent } from "vue";
import jobHttp from "@/http/job";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import FilterModalContent from "@/components/FilterModalContent.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
export default defineComponent({
    components: {
        ActionTableColumn,
        ConfirmModal,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        FilterModalContent,
        BaseFormGroup,
        BaseButtonDefault,
        BaseLink,
    },
    mixins: [
        filterHelper(),
        positionFilter(),
        helperMixin,
        infinityScrollTable(jobHttp(), {
            position_in: [],
        }),
    ],
    data() {
        return {
            incomingApplicantEvent: null,
            submittedFilter: {},
        };
    },
    mounted() {
        this.incomingApplicantEvent = this.pubSub.on(this.EventNames.IncomingApplication, () => this.reload());
    },
    beforeUnmount() {
        this.incomingApplicantEvent ? this.incomingApplicantEvent() : "";
    },
});
