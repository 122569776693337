import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";
export default createBaseChildrenRecordRaw("company", {
    index: {
        name: RouteNames.Company,
        component: () => import("@/pages/Superadmin/Company/Index.vue"),
        meta: {
            withContentTools: true,
            rootName: RouteNames.Company
        }
    },
    detail: {
        name: RouteNames.CompanyDetail,
        component: () => import("@/pages/Superadmin/Company/Detail.vue"),
        meta: {
            rootName: RouteNames.Company
        }
    }
});
