export default class PubSub {
    constructor() {
        this.events = {};
    }
    on(eventName, callback) {
        this.events[eventName] = this.events[eventName] || [];
        const index = this.events[eventName].push(callback) - 1;
        return () => {
            this.events[eventName] = index != 0 ? this.events[eventName].slice(index, 1) : [];
        };
    }
    trigger(eventName, val) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(callback => callback(val));
        }
    }
}
