import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import ChooseFleetModal from "@/components/Planning/ChooseFleetModal.vue";
import CrewOnBoard from "@/components/Planning/CrewOnBoard.vue";
import { CrewStatus, Readliness } from "@/enums";
import crewBoardHttp from "@/http/crewBoard";
import { helperMixin } from "@/mixins";
import selectionStore from "@/store/selection";
import { defineComponent, ref } from "vue";
import AddCrewToFleetModal from "@/components/Planning/AddCrewToFleetModal.vue";
import { toast } from "@/template/app";
import { trans } from "@/helpers";
export default defineComponent({
    components: {
        AddCrewToFleetModal,
        LoadingPage,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        BaseFormGroup,
        ChooseFleetModal,
        BaseButtonPrimary,
        ActionTableColumn,
        DropdownItem,
        CrewOnBoard,
    },
    mixins: [helperMixin],
    setup() {
        return {
            crewOnBoard: ref(null),
        };
    },
    computed: {
        selectionStore,
        crewBoardHttp,
        currentFleet() {
            return this.fleetTabs.find(fleet => fleet.id === this.activeTabId);
        }
    },
    data() {
        return {
            addCrewModal: false,
            exportingExcelLoading: false,
            CrewStatus,
            Readliness,
            showChooseFleetModal: false,
            fleetTabs: [],
            activeTabId: "",
        };
    },
    mounted() {
        this.selectionStore.fetch();
    },
    methods: {
        crewBoardSaved() {
            this.addCrewModal = false;
            this.crewOnBoard.reload();
        },
        addCrew() {
            if (!this.currentFleet) {
                toast("warning", trans("layout.planning.choose_fleet_first"), 0);
                return;
            }
            this.addCrewModal = true;
        },
        async exportToExcel() {
            this.exportingExcelLoading = true;
            await this.crewBoardHttp.exportPlanning({ ...this.crewOnBoard.filter });
            setTimeout(() => {
                this.exportingExcelLoading = false;
            }, 1000);
        },
        async setActiveTabId(id) {
            // if (this.activeTabId === id || !id) return;
            var _a;
            this.activeTabId = id;
            await this.$nextTick();
            this.crewOnBoard.loading = false;
            this.crewOnBoard.filter.fleet_id = id;
            if (this.appStore.state.activeMenu) {
                this.appStore.state.activeMenu.breadcrumbs = [
                    (_a = this.fleetTabs.find((fleet) => fleet.id === id)) === null || _a === void 0 ? void 0 : _a.ship_name,
                ];
            }
        },
        removeTab(index) {
            var _a, _b, _c;
            if (this.activeTabId === this.fleetTabs[index].id) {
                this.crewOnBoard.rows = [];
                this.setActiveTabId((_b = (_a = this.fleetTabs[index + 1]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : (_c = this.fleetTabs[index - 1]) === null || _c === void 0 ? void 0 : _c.id);
            }
            this.fleetTabs.splice(index, 1);
        },
        addTab(fleet) {
            if (!this.fleetTabs.find((f) => f.id === fleet.id)) {
                this.fleetTabs.push(fleet);
                this.setActiveTabId(fleet.id);
            }
        },
    },
});
