import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class CompanySubscriptionHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["create", "delete"];
    }
    baseURL() {
        return "company_subscription";
    }
}
const companySubscriptionHttp = createInstance(CompanySubscriptionHttp);
export default companySubscriptionHttp;
