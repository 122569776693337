import { helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
import newsHttp from "@/http/news";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
export default defineComponent({
    components: {
        TeleportToContentFooter,
        BaseFormGroup,
        BaseFormApp,
        BaseFileInput,
    },
    mixins: [helperMixin, saveAndUpdate(newsHttp())],
    data() {
        return {
            currentImageLink: "",
        };
    },
    methods: {
        setForm() {
            var _a, _b, _c;
            this.form.title = (_a = this.data) === null || _a === void 0 ? void 0 : _a.title;
            this.form.body = (_b = this.data) === null || _b === void 0 ? void 0 : _b.body;
            this.form.image = null;
            this.currentImageLink = (_c = this.data) === null || _c === void 0 ? void 0 : _c.image_link;
        },
    },
});
