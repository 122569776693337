import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class Crew extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["update", "delete"];
    }
    baseURL() {
        return "crew";
    }
    updateCrew(id, data) {
        return this.request("POST", `${this.baseURL()}/crew_update/${id}`, {
            data
        });
    }
    updateCrewCompany(id, data) {
        return this.request("POST", `${this.baseURL()}/crew_update_company/${id}`, {
            data
        });
    }
    addToBoard(data) {
        return this.request("POST", `${this.baseURL()}/add_to_board`, {
            data: {
                fleet_id: data.fleet_id,
                crews: data.crews,
            },
        });
    }
    async paginate(filter) {
        var _a;
        return super.paginate({
            ...filter,
            position_in: (_a = filter === null || filter === void 0 ? void 0 : filter.position_in) === null || _a === void 0 ? void 0 : _a.join(","),
        });
    }
}
const o = createInstance(Crew);
export const crewHttp = o;
export default o;
