import { infinityScrollTable, helperMixin, filterHelper, positionFilter } from "@/mixins";
import { defineComponent } from "vue";
import jobHttp from "@/http/job";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
export default defineComponent({
    components: {
        BaseTable,
        BaseTableRow,
        BaseTableColumn
    },
    mixins: [
        filterHelper(),
        positionFilter(),
        helperMixin,
        infinityScrollTable(jobHttp())
    ],
    props: {
        companyId: {
            required: true
        }
    },
    mounted() {
        this.filter.company_id = this.companyId;
    }
});
