import { SelectionType } from "@/enums";
import { createInstance } from "@/lib/fn";
import Store from "@/lib/store";
import selectionHttp from "@/http/selection";
import selection from "@/http/selection";
import { toast } from "@/template/app";
class Selection extends Store {
    states() {
        return {
            [SelectionType.JobHiringNationality]: [],
            [SelectionType.JobPurpose]: [],
            [SelectionType.JobRequirement]: [],
            [SelectionType.JobPosition]: [],
            [SelectionType.JobContractType]: [],
            [SelectionType.JobSalaryType]: [],
            [SelectionType.JobBenefit]: [],
            [SelectionType.Currency]: [],
            [SelectionType.FleetComunication]: [],
            [SelectionType.FleetComunicationDetail]: [],
        };
    }
    constructor() {
        super();
        // keep data until browser is refresh
        this.haventFetched = [];
        this.haventFetched = Object.keys(this.state)
            .filter(key => Object.values(SelectionType)
            .indexOf(key) !== -1);
    }
    getTypes() {
        return SelectionType;
    }
    async fetch() {
        const haventFetched = [...this.haventFetched];
        this.haventFetched = [];
        for (let i = 0; i < haventFetched.length; i++) {
            this.state[haventFetched[i]] = await selectionHttp().all(haventFetched[i]);
        }
        return true;
    }
    findById(id, collections) {
        if (!collections)
            return;
        for (let i = 0; i < collections.length; i++) {
            if (collections[i].id === id) {
                return collections[i];
            }
            let found = this.findById(id, collections[i].recursive_childs);
            if (found)
                return found;
        }
    }
    async remove(type, id, parentId) {
        var _a;
        let parent;
        const { status, message } = await selection().delete(id);
        if (status == 200) {
            if (!parentId) {
                parent = this.state[type];
            }
            else {
                parent = (_a = this.findById(parentId, this.state[type])) === null || _a === void 0 ? void 0 : _a.recursive_childs;
            }
            if (Array.isArray(parent)) {
                parent.splice(parent.findIndex(d => d.id === id), 1);
            }
            return true;
        }
        toast('error', message, 0);
        return false;
    }
}
export const selectionStore = createInstance(Selection);
export default selectionStore;
