import {makeFirstCapital, trans} from '@/helpers'

/**
 * get current window size
 * @type {{smUp(): *, md(): boolean, sm(): boolean, xs(): boolean, lg(): boolean}}
 */
const currentScreenSize = {
    /** Small devices (phones, 576px kebawah) */
    xs() {
        return window.innerWidth <= 576;
    },
    /** Medium devices (tablets, phones 768px kebawah) */
    sm() {
        return window.innerWidth <= 768;
    },
    /** Medium devices (tablets, 768px keatas) */
    smUp() {
        return window.innerWidth > 768 && window.innerWidth < 992;
    },
    /** Large devices (desktops, 992px and keatas) */
    md() {
        return window.innerWidth >= 992;
    },
    /** Large devices (desktops, 1368 and keatas) */
    lg() {
        return window.innerWidth >= 1368;
    },
};

/**
 * polyfill for transition end
 * @type {*|boolean}
 */
const transitionEnd = (() => {
    let i,
        el = document.createElement("div"),
        transitions = {
            transition: "transitionend",
            OTransition: "otransitionend", // oTransitionEnd in very old Opera
            MozTransition: "transitionend",
            WebkitTransition: "webkitTransitionEnd",
        };

    for (i in transitions) {
        if (transitions.hasOwnProperty(i) && el.style[i] !== undefined) {
            return transitions[i];
        }
    }

    return false;
})();

const Selectors = Object.freeze({
    ROOT: ".js-root",
    SIDEBAR: ".js-sidebar",
    SIDEBAR_TOGGLER: ".js-sidebar-toggler",
    SEARCH_TOGGLER: ".js-search-toggler",
    SEARCH_INPUT: ".js-search-input",
    NAV_ITEM_ACTIVE: ".uwu-nav-item.is-active",
    NAV_ITEM_HAS_MENU_OPEN: ":scope > .uwu-nav-item.has-menu.menu-open",
    NAV_ITEM_CARET: ".mdi-chevron-right",
    UWU_NAV_MENU: ".uwu-nav-menu",
    CONTENT_BODY: ".js-content-body",
});

const ClassList = Object.freeze({
    UWU_MODAL_CONTAINER: 'uwu-modal-container',
    UWU_NAV_MENU_TOGGLER: 'js-menu-toggler',
    TOP_NAV: 'js-top-nav',
    SIDEBAR: 'js-sidebar',
    UWU_MODAL_TOGGLER: "uwu-modal-toggler",
    UWU_MODAL: "uwu-modal",
    UWU_MODAL_DIALOG: "uwu-modal-dialog",
    NAV: "uwu-nav",
    NAV_ITEM: "uwu-nav-item",
    NAV_ITEM_MENU: "uwu-nav-item-menu",
    NAV_ITEM_MENU_OPEN: "menu-open",
    HAS_MENU: "has-menu",
    COLLAPSING: "collapsing",
    IS_ACTIVE: "is-active",
    SMALL_SIDEBAR: "small-sidebar",
    ON_HOVER: "on-hover",
    WITH_SCROLL: "with-scroll",
    UWU_SEARCH: "uwu-search",
    UWU_SEARCH_TOGGLER: "uwu-search-toggler",
    SIDEBAR_TOGGLER: "js-sidebar-toggler",
    UWU_NAV_MENU: 'uwu-nav-menu',
    UWU_NAV_MENU_BODY: 'uwu-nav-menu-body',
    UWU_NAV_MENU_CONTAINER: 'uwu-nav-menu-container',

    UWU_DROPDOWN: 'uwu-dropdown',
    UWU_DROPDOWN_TOGGLER: 'uwu-dropdown-toggler',
    UWU_DROPDOWN_MENU: 'uwu-dropdown-menu',
    UWU_DROPDOWN_ITEM: 'uwu-dropdown-item'
});

export const Layout = (() => {
    const root = document.querySelector(Selectors.ROOT);

    if (!root) return;

    return {
        root,
        sidebar: document.querySelector(Selectors.SIDEBAR),
        contentBody: root.querySelector(Selectors.CONTENT_BODY),
        sidebarToggle: document.querySelector(Selectors.SIDEBAR_TOGGLER),
    };
});

export const Sidebar = (() => {
    let _exitHoverSidebar = false;
    let _onCollapsingMenu = false;
    let _overflowTimeout = 0;

    /**
     * @param {HTMLElement} toggle
     * @param {number} state 1|0
     */
    const toggleMotion = (toggle, state) => {
        const menu = toggle.parentElement.querySelector(`.${ClassList.NAV_ITEM_MENU}`);

        const onTransitionEnd = () => {
            toggle.parentElement.removeAttribute('style');
            toggle.parentElement.classList.remove(ClassList.COLLAPSING);
            toggle.parentElement.removeEventListener(transitionEnd, onTransitionEnd);
            toggle.parentElement.classList.remove(ClassList.COLLAPSING);
            menu?.removeAttribute("style");

            _onCollapsingMenu = false;

            // if sidebar wait for close
            if (_exitHoverSidebar) {
                onSidebarMouseExit();
                _exitHoverSidebar = false;
            }

            initScroll();
        }

        _onCollapsingMenu = true;
        toggle.parentElement.addEventListener(transitionEnd, onTransitionEnd);

        if (menu) {
            menu.style.setProperty("display", "block", "important");
        }

        if (state === 0) {
            toggle.parentElement.classList.remove(ClassList.NAV_ITEM_MENU_OPEN);
            toggle.parentElement.style.height = `${toggle.parentElement.offsetHeight}px`;
            // make height same with item

            // wait for transitioning...
            setTimeout(() => {
                toggle.parentElement.style.height = `${toggle.clientHeight}px`;
            }, 0.01);
        } else if (state === 1) {
            toggle.parentElement.classList.add(ClassList.NAV_ITEM_MENU_OPEN);

            //  calculate height when open
            let h = toggle.parentElement.offsetHeight;

            toggle.parentElement.style.height = `${toggle.clientHeight}px`;
            // wait for transitioning...
            setTimeout(() => {
                toggle.parentElement.style.height = `${h}px`;
            }, 0.01);
        }

        // if browser not supported event transition
        if (!transitionEnd) {
            onTransitionEnd();
        }
    }

    /**
     * @param {HTMLElement} exceptNavItem
     */
    const hideOtherMenu = (exceptNavItem) => {
        try {
            const navItems = exceptNavItem.parentElement.parentElement.querySelectorAll(Selectors.NAV_ITEM_HAS_MENU_OPEN);

            for (let i = 0; i != navItems.length; i++) {
                if (navItems[i] === exceptNavItem.parentElement) {
                    continue;
                }

                toggleMotion(
                    navItems[i].querySelectorAll(`.${ClassList.NAV_ITEM}`)[0],
                    0
                );
            }
        } catch (e) {

        }
    }

    /**
     * @param {HTMLElement} navItem
     */
    const toggleCollapseMenu = (navItem) => {
        if (
            navItem.parentElement.classList.contains(ClassList.NAV_ITEM) &&
            navItem.parentElement.classList.contains(ClassList.HAS_MENU)
        ) {
            if (!_onCollapsingMenu) {
                if (currentScreenSize.sm() && !Layout().root.classList.contains('open-sidebar')) {
                    Sidebar.toggleShow();
                } else {
                    if (
                        navItem.parentElement.classList.contains(ClassList.NAV_ITEM_MENU_OPEN)
                    ) {
                        toggleMotion(navItem, 0);
                    } else {
                        hideOtherMenu(navItem);
                        toggleMotion(navItem, 1);
                    }
                }
            }
        } else {
            Layout()?.root.classList.remove("open-sidebar");
        }
    };

    const removeParentMenuOpenActive = () => {
        Layout()?.sidebar.querySelectorAll('.uwu-nav-item.has-menu.menu-open.is-active')
            .forEach(el => {
                el.classList.remove('is-active');
            });
    }

    const initActiveMenu = () => {
        const elements = Layout()?.sidebar.querySelectorAll(Selectors.NAV_ITEM_ACTIVE);

        /**
         * @param {HTMLElement} menu
         */
        const openMenu = (menu) => {
            // set class of element "nav-item.has-menu"
            menu.parentElement.classList.add(ClassList.NAV_ITEM_MENU_OPEN);
            menu.parentElement.classList.add(ClassList.IS_ACTIVE);

            // if parent "nav-item.has-menu" is "nav-item-menu"
            if (menu.parentElement.parentElement.classList.contains(ClassList.NAV_ITEM_MENU)) {
                // recursion
                openMenu(menu.parentElement.parentElement);
            }

        }

        for (let i = 0; i !== elements.length; i++) {
            const el = elements[i];

            if (el.parentElement.classList.contains(ClassList.NAV_ITEM_MENU)) {
                openMenu(el.parentElement);
            }
        }
    }

    const onSidebarMouseExit = () => {
        if (Layout()?.root.classList.contains(ClassList.SMALL_SIDEBAR) && !currentScreenSize.xs()) {
            // if on collapsing menu
            // wait unti transition end
            // then close sidebar
            if (_onCollapsingMenu) {
                _exitHoverSidebar = true;
            } else {
                Layout()?.sidebar.classList.remove(ClassList.ON_HOVER);
            }

        }
    }

    const onSidebarMouseEnter = () => {
        clearTimeout(_overflowTimeout);

        if (Layout()?.root.classList.contains(ClassList.SMALL_SIDEBAR)) {
            Layout()?.sidebar.classList.add(ClassList.ON_HOVER);
        }
    }

    const bindListener = () => {
        Layout()?.sidebar.addEventListener("mouseenter", onSidebarMouseEnter, false);
        Layout()?.sidebar.addEventListener("mouseleave", onSidebarMouseExit, false);
    }

    const initScroll = () => {
        Layout()?.sidebar.classList.remove(ClassList.WITH_SCROLL);

        if (Layout()?.sidebar.scrollHeight > Layout()?.sidebar.clientHeight) {
            Layout()?.sidebar.classList.add(ClassList.WITH_SCROLL);
        }
    }

    const toggleShow = () => {
        if (currentScreenSize.sm()) {
            Layout()?.root.classList.toggle("open-sidebar");
        } else {
            Layout()?.root.classList.toggle("small-sidebar");
        }
    }

    return {
        toggleShow,
        bindListener,
        toggleCollapseMenu,
        initActiveMenu,
        removeParentMenuOpenActive,
        initScroll,
        get oncollapsing() {
            return _onCollapsingMenu;
        }
    };
})();

/**
 * @param {HTMLElement} el
 */
export const handleUwuSearchToggleClick = (el) => {
    if (el.parentElement.classList.contains(ClassList.UWU_SEARCH)) {
        el.parentElement.classList.toggle("show-input");

        let input = el.parentElement.querySelector("input");

        if (input) {
            input.value = "";
            input.focus();
            input.addEventListener("blur", function () {
                if (!input.value) {
                    el.parentElement.classList.remove("show-input");
                }
            });
        }
    }
}

/**
 * @param {Event} e
 */
const onSidebarToggleClick = (e) => {
    try {
        e.stopPropagation();
        Sidebar.toggleShow();
    } catch (error) {

    }
};

const onWindowResize = () => {
    Sidebar.initScroll();
}

/**
 * @param {HTMLElement} el
 * @param {string} cls
 * @return {HTMLElement}
 */
const findAncestor = (el, cls) => {
    while ((el = el.parentElement) && !el.classList.contains(cls)) ;
    return el;
}

/**
 * @param {HTMLElement} navItem
 * @param {HTMLElement} target
 */
const onNavItemClick = (navItem, target) => {
    let tmp = null;

    if (!findAncestor(target, ClassList.UWU_NAV_MENU_CONTAINER)) {
        document.body.querySelector(Selectors.UWU_NAV_MENU + ".is-open")
            ?.classList.remove('is-open');
    }

    if (!findAncestor(target, ClassList.UWU_DROPDOWN)) {
        document.body.querySelector("." + ClassList.UWU_DROPDOWN + ".is-open")
            ?.classList.remove('is-open');
    }

    if (findAncestor(navItem, ClassList.SIDEBAR)) {
        Sidebar.toggleCollapseMenu(target.classList.contains(ClassList.NAV_ITEM) ? target : target.parentElement);
    } else if (findAncestor(navItem, ClassList.TOP_NAV)) {
        if (!findAncestor(target, ClassList.UWU_NAV_MENU_CONTAINER) || findAncestor(target, ClassList.UWU_NAV_MENU_BODY)) {
            if (tmp = findAncestor(target, ClassList.UWU_NAV_MENU_BODY)) {
                findAncestor(target, ClassList.NAV_ITEM)?.querySelector("button").classList.remove("active");
            } else {
                if (target.nodeName === "BUTTON") {
                    target.classList?.add('active');
                } else {
                    target.parentElement.classList?.add('active');
                }
            }

            const menu = navItem.querySelector(Selectors.UWU_NAV_MENU);

            document.body.querySelectorAll(Selectors.UWU_NAV_MENU).forEach(el => {
                if (el != menu) {
                    el.classList.remove('is-open');
                    el.parentElement.querySelector("button")?.classList.remove("active");
                }
            });

            menu?.classList?.toggle('is-open');
        }
    }
}

/**
 * @param {HTMLElement} toggler
 * @param {HTMLElement} target
 */
const onDropdownTogglerClick = (toggler, target) => {
    if (!findAncestor(target, ClassList.UWU_DROPDOWN_MENU)) {
        const dropdown = findAncestor(toggler, ClassList.UWU_DROPDOWN);

        const anotherDropdown = document.body.querySelector("." + ClassList.UWU_DROPDOWN + ".is-open");

        if (anotherDropdown && dropdown != anotherDropdown) {
            anotherDropdown.classList.remove('is-open');
        }

        dropdown?.classList?.toggle('is-open');
    }
}

/**
 * @param {Event} e
 */
const onWindowClick = (e) => {
    let tmp;

    if (!e.target) return;

    // console.log(e.target.classList.contains('uwu-nav-menu-dialog'))

    if (e.target.classList.contains('uwu-nav-menu-dialog')) {
        document.body.querySelector("." + ClassList.UWU_NAV_MENU + ".is-open")
            ?.classList.remove('is-open');
    } else if (e.target.parentNode?.classList?.contains(ClassList.SIDEBAR_TOGGLER)) {
        onSidebarToggleClick(e);
    } else if (e.target.classList.contains(ClassList.SIDEBAR_TOGGLER)) {
        onSidebarToggleClick(e.target);
    } else if (tmp = findAncestor(e.target, ClassList.NAV_ITEM)) {
        onNavItemClick(tmp, e.target);
    } else if (!e.target.classList.contains(ClassList.NAV) && Layout()?.root.classList.contains("open-sidebar")) {
        if (currentScreenSize.sm()) {
            Sidebar.toggleShow();
        }
    } else if (e.target.classList.contains(ClassList.UWU_SEARCH_TOGGLER)) {
        handleUwuSearchToggleClick(e.target);
    } else if (e.target.parentNode?.classList.contains(ClassList.UWU_SEARCH_TOGGLER)) {
        handleUwuSearchToggleClick(e.target.parentNode);
    } else if (e.target.classList.contains(ClassList.UWU_MODAL_TOGGLER)) {
        hideModal(
            findAncestor(e.target, ClassList.UWU_MODAL)
        );
    } else if (e.target.classList.contains(ClassList.UWU_MODAL)) {
        hideModal(e.target);
    } else if (tmp = findAncestor(e.target, ClassList.UWU_MODAL)) {
        if (!findAncestor(e.target, ClassList.UWU_MODAL_CONTAINER)) {
            hideModal(tmp);
        }
    } else if (e.target.parentNode?.classList.contains(ClassList.UWU_MODAL_TOGGLER)) {
        hideModal(
            findAncestor(e.target.parentNode, ClassList.UWU_MODAL)
        );
    } else if (tmp = findAncestor(e.target, ClassList.UWU_DROPDOWN_TOGGLER)) {
        onDropdownTogglerClick(tmp, e.target);
    } else {
        if (!findAncestor(e.target, ClassList.UWU_NAV_MENU_CONTAINER)) {
            document.body.querySelector(Selectors.UWU_NAV_MENU + ".is-open")
                ?.parentElement?.querySelector("button")?.classList?.remove("active");

            document.body.querySelector(Selectors.UWU_NAV_MENU + ".is-open")
                ?.classList.remove('is-open');

        }

        if (!findAncestor(e.target, ClassList.UWU_DROPDOWN) || e.target.classList.contains(ClassList.UWU_DROPDOWN_ITEM) || (tmp = findAncestor(e.target, ClassList.UWU_DROPDOWN_ITEM))) {
            document.body.querySelector("." + ClassList.UWU_DROPDOWN + ".is-open")
                ?.classList.remove('is-open');
        }
    }
};

/**
 * @type {Array.<{ el: HTMLElement, callback: Function }>}
 */
const showModalSubs = [];

/**
 * @type {Array.<{ el: HTMLElement, callback: Function }>}
 */
const hideModalSubs = [];

/**
 * @type {Array.<(e: Event): void>}
 */
const contentBodyScrollSubs = [];

/**
 *
 * @param {HTMLElement} el
 * @param {Function} callback
 */
export const onModalShow = (el, callback) => {
    showModalSubs.push({
        el, callback
    });
}

/**
 *
 * @param {HTMLElement} el
 * @param {Function} callback
 */
export const showModal = (el) => {
    if (!el) return;

    document.body.classList.add('uwu-modal-open');
    el.classList.add('is-open');

    showModalSubs.forEach(obj => {
        if (obj.el == el) {
            obj.callback();
        }
    });
}

/**
 *
 * @param {HTMLElement} el
 * @param {Function} callback
 */
export const onModalHide = (el, callback) => {
    hideModalSubs.push({
        el, callback
    });
}

/**
 *
 * @param {HTMLElement} el
 */
export const hideModal = (el) => {
    if (!el) return;

    el.classList.remove('is-open');

    if (!document.body.querySelectorAll('.uwu-modal.is-open').length) {
        document.body.classList.remove('uwu-modal-open');
    }

    hideModalSubs.forEach(obj => {
        if (obj.el == el) {
            obj.callback();
        }
    });
}

/**
 * @param {Function} callback
 */
export const onContentBodyScroll = (callback) => {
    return contentBodyScrollSubs.push(callback) - 1;
}

export const offContentBodyScroll = (i) => {
    contentBodyScrollSubs.splice(i, 1);
}

const uwuToast = () => {
    let uwuToast = document.body.querySelector(".uwu-toast");

    if (!uwuToast) {
        uwuToast = document.createElement("div");
        uwuToast.className = "uwu-toast";

        document.body.prepend(uwuToast);
    }

    return uwuToast;
}

/**
 * @param {"info" | "error" | "success" | "warning"} flag
 * @param {string} msg
 * @param {number} timeout in milisecond set 0 for unlimited
 */
export const toast = (flag, msg, timeout = 10) => {
    const item = document.createElement("div");
    const progressBar = document.createElement("div");
    const progress = document.createElement("div");

    const title = document.createElement("span");

    const leftIcon = document.createElement("div");
    const leftIconContent = document.createElement("i");

    const rightIcon = document.createElement("div");
    const rightIconContent = document.createElement("i");

    const body = document.createElement("div");

    const msgContent = document.createElement("span");

    const buttonOk = document.createElement("button");

    let second = 0;

    const toast = uwuToast();

    rightIconContent.className = "mdi mdi-close text-base";

    if (flag === "info") {
        leftIconContent.className = "mdi mdi-information text-blue-500";
    } else if (flag === "success") {
        leftIconContent.className = "mdi mdi-check-bold text-green-500";
    } else if (flag === "warning") {
        leftIconContent.className = "mdi mdi-alert text-yellow-500";
    } else if (flag === "error") {
        leftIconContent.className = "mdi mdi-alert-octagon text-red-500";
    }

    item.className = "uwu-toast-item";
    progressBar.className = "uwu-toast-item-progress-bar";
    progress.className = "uwu-toast-item-progress";

    leftIcon.className = "uwu-toast-icon";
    title.className = "uwu-toast-title";
    rightIcon.className = "uwu-toast-icon";
    body.className = "uwu-toast-body";

    title.innerText = makeFirstCapital(flag);

    leftIcon.appendChild(leftIconContent);
    rightIcon.appendChild(rightIconContent);

    msgContent.innerHTML = msg;
    msgContent.className = "uwu-toast-desc";

    body.appendChild(leftIcon);
    body.appendChild(msgContent);

    buttonOk.innerText = trans("layout.button.ok");
    buttonOk.className = "uwu-toast-btn";

    // item.appendChild(leftIcon);
    item.appendChild(title);
    item.appendChild(rightIcon);
    item.appendChild(body);
    item.appendChild(buttonOk);
    item.appendChild(progressBar);
    item.appendChild(progress);

    const removeToast = () => {
        toast.parentNode.removeChild(toast);
    };

    buttonOk.addEventListener("click", removeToast);

    rightIcon.addEventListener("click", removeToast);


    if (timeout > 0) {
        toast.classList.add("uwu-toast-timeout");

        progress.addEventListener(transitionEnd, () => {
            if (second === timeout + 1) {
                removeToast();
            }
        });

        const interval = setInterval(() => {
            progress.style.width = `${second / timeout * 100}%`;

            if (++second === timeout + 1) {
                clearInterval(interval);
            }
        }, 100);
    } else {
        progressBar.remove();
    }

    toast.appendChild(item);

    requestAnimationFrame(() => {
        toast.classList.add("uwu-toast-show");
    });
}

export const showLoading = () => {
    const loadingHtml = `
    <div class="loading-page">
      <div class="content">
        <div class="uwu-loader">
          <div class="uwu-loader-icon"></div>
        </div>
        <slot></slot>
      </div>
    </div>
  `;
    const loadingElement = document.createElement('div');
    loadingElement.innerHTML = loadingHtml;
    document.body.prepend(loadingElement);
};

export const hideLoading = () => {
    const loadingElement = document.body.querySelector('.loading-page');
    if (loadingElement) {
        loadingElement.remove();
    }
};

export let currKey = 0;

window.addEventListener('keydown', function (e) {
    currKey = 1;
});

window.addEventListener('keyup', function (e) {
    currKey = 0;
});


export default (() => {
    window.onclick = onWindowClick;
    window.addEventListener("resize", onWindowResize);
    Sidebar.initActiveMenu();
    Sidebar.bindListener();
    Sidebar.initScroll();

    Layout()?.root.querySelector(Selectors.CONTENT_BODY)
        .addEventListener('scroll', function (e) {
            contentBodyScrollSubs.forEach(callback => callback(e));
        });
});


