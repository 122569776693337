import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class Qna extends BaseResourceHttp {
    baseURL() {
        return "qna";
    }
    reject(id, reject_reason) {
        return this.request("POST", this.url(`reject/${id}`), {
            data: {
                reject_reason
            }
        });
    }
    approve(id) {
        return this.request("POST", this.url(`approve/${id}`));
    }
}
export const qnaHttp = createInstance(Qna);
export default qnaHttp;
