import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import CheckboxActionTool from "@/components/CheckboxActionTool.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import JobPoster from "@/components/Job/JobPoster.vue";
import RequirementCheckbox from "@/components/Job/RequirementCheckbox.vue";
import SelectionSaveModal from "@/components/Selection/SelectionSaveModal.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import { SelectionType } from "@/enums";
import jobHttp from "@/http/job";
import { helperMixin, saveAndUpdate, selectionSaveModal } from "@/mixins";
import authStore from "@/store/auth";
import selectionStore from "@/store/selection";
import { defineComponent } from "vue";
export default defineComponent({
    components: {
        BaseFormApp,
        BaseFormGroup,
        BaseCheckbox,
        RequirementCheckbox,
        TeleportToContentFooter,
        BaseButtonDefault,
        BaseModal,
        JobPoster,
        BaseButtonPrimary,
        ConfirmModal,
        CheckboxActionTool,
        SelectionSaveModal,
    },
    mixins: [
        helperMixin,
        saveAndUpdate(jobHttp()),
        selectionSaveModal,
    ],
    computed: {
        selectionStore,
        authStore,
        defaultContractType() {
            return this.selectionStore.state.job_contract_type[0];
        },
        contractDurations() {
            var _a;
            const arrs = [];
            for (let i = 0; i < 48; i++) {
                arrs.push({
                    value: i + 1,
                    text: i + 1 + " " + ((_a = this.defaultContractType) === null || _a === void 0 ? void 0 : _a.name),
                });
            }
            return arrs;
        },
    },
    data() {
        return {
            SelectionType,
            showPreviewModal: false,
            showConfirmSubmitModal: false,
        };
    },
    methods: {
        async processSubmit() {
            await this.submit();
            this.showConfirmSubmitModal = false;
        },
        confirmSubmit() {
            this.showConfirmSubmitModal = true;
        },
        onMounted() {
            return this.selectionStore.fetch();
        },
        setForm() {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            this.form.hiring_nationality_id = (_a = this.data) === null || _a === void 0 ? void 0 : _a.hiring_nationality.id;
            this.form.purpose_id = (_b = this.data) === null || _b === void 0 ? void 0 : _b.purpose.id;
            this.form.position_id = (_c = this.data) === null || _c === void 0 ? void 0 : _c.position.id;
            this.form.join_asap = (_d = this.data) === null || _d === void 0 ? void 0 : _d.join_asap;
            this.form.estimate_join_date = ((_e = this.data) === null || _e === void 0 ? void 0 : _e.estimate_join_date) || null;
            this.form.contract_on_duration = ((_f = this.data) === null || _f === void 0 ? void 0 : _f.contract_on_duration) || 1;
            this.form.contract_off_duration =
                ((_g = this.data) === null || _g === void 0 ? void 0 : _g.contract_off_duration) || null;
            this.form.salary_type_id = ((_h = this.data) === null || _h === void 0 ? void 0 : _h.salary_type.id) || "";
            this.form.salary_rate = this.numberFormat(((_j = this.data) === null || _j === void 0 ? void 0 : _j.salary_rate) || "");
            this.form.salary_currency_id = ((_k = this.data) === null || _k === void 0 ? void 0 : _k.salary_currency.id) || "";
            this.form.requirements =
                ((_l = this.data) === null || _l === void 0 ? void 0 : _l.requirements.map((requirement) => requirement.id)) || [];
            this.form.benefits =
                ((_m = this.data) === null || _m === void 0 ? void 0 : _m.benefits.map((benefit) => benefit.id)) || [];
            this.form.last_submission_date = ((_o = this.data) === null || _o === void 0 ? void 0 : _o.last_submission_date) || "";
            this.form.note = ((_p = this.data) === null || _p === void 0 ? void 0 : _p.note) || "";
            if (this.defaultContractType) {
                this.form.contract_on_type_id = this.defaultContractType.id;
                this.form.contract_off_type_id = this.defaultContractType.id;
            }
        },
    },
    watch: {
        defaultContractType: {
            immediate: true,
            deep: true,
            handler(val) {
                this.form.contract_on_type_id = val === null || val === void 0 ? void 0 : val.id;
                this.form.contract_off_type_id = val === null || val === void 0 ? void 0 : val.id;
            },
        },
    },
});
