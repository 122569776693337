import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { helperMixin } from "@/mixins";
import contentTool from "@/store/contentTool";
import { handleUwuSearchToggleClick } from "@/template/app";
import { defineComponent, ref } from "@vue/runtime-core";
export default defineComponent({
    mixins: [helperMixin],
    components: {
        BaseButtonPrimary,
        BaseLink,
        Error,
    },
    setup() {
        return {
            uwuSearchInput: ref(null),
        };
    },
    computed: {
        contentTool,
        pageTitle() {
            var _a;
            if (!this.appStore.state.activeMenu)
                return "";
            return ((_a = (this.appStore.state.activeMenu.parent_title
                ? this.trans(`menu.${this.appStore.state.activeMenu.parent_title}`)
                : null)) !== null && _a !== void 0 ? _a : this.trans(`menu.${this.appStore.state.activeMenu.title}`));
        },
        breadcrumbs() {
            var _a, _b, _c, _d;
            return [
                ...((_b = (_a = this.appStore.state.activeMenu) === null || _a === void 0 ? void 0 : _a.breadcrumbs) !== null && _b !== void 0 ? _b : []),
                ...((_d = (_c = this.currentRoute.meta) === null || _c === void 0 ? void 0 : _c.breadcrumbs) !== null && _d !== void 0 ? _d : []),
            ];
        },
    },
    data() {
        return {
            search: "",
        };
    },
    methods: {
        transBreadcrumb(breadcrumb) {
            const trans = this.trans(`layout.breadcrumbs.${breadcrumb}`);
            return trans === `layout.breadcrumbs.${breadcrumb}` ? breadcrumb : trans;
        },
    },
    watch: {
        currentRoute() {
            this.appStore.state.contentHeaderCustomClass = "";
            if (this.search) {
                if (this.uwuSearchInput) {
                    handleUwuSearchToggleClick(this.uwuSearchInput);
                }
                this.search = "";
                this.contentTool.state.search = "";
            }
            this.contentTool.state.filterCount = 0;
            let breadCrumbsTitle = "";
            this.breadcrumbs.forEach((val) => {
                breadCrumbsTitle +=
                    " · " +
                        this.makeFirstCapital(this.trans(`layout.breadcrumbs.${val}`));
            });
            this.setPageTitle(this.makeFirstCapital(this.pageTitle) + breadCrumbsTitle);
        },
    },
});
