import BaseInput from "@/components/Base/BaseInput.vue";
import { EventNames } from "@/enums";
import { helperMixin } from "@/mixins";
import { toast } from "@/template/app";
import { defineComponent } from "vue";
export default defineComponent({
    mixins: [helperMixin],
    emits: ["update:modelValue", "change", "add-button-click"],
    components: { BaseInput },
    props: {
        readonly: Boolean,
        disabled: Boolean,
        placeholder: {
            type: String,
        },
        absolutePosition: {
            type: Boolean,
            default: true,
        },
        withAddButton: {
            type: Boolean,
            default: false,
        },
        selectClass: String,
        removedSelectedItem: {
            type: Function,
            required: false,
            default: undefined,
        },
        keyValue: {
            type: String,
            default: "value",
        },
        keyText: {
            type: String,
            default: "text",
        },
        filterPaginate: {
            type: Object,
        },
        resourceHttp: {
            type: Object,
            default: null,
        },
        modelValue: {
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        filteredOptions() {
            if (this.search && !this.resourceHttp) {
                return this.options.filter((item) => String(item[this.keyText])
                    .toLowerCase()
                    .match(this.search.toLowerCase()));
            }
            return this.options;
        },
    },
    data() {
        return {
            tmpItems: [],
            columns: [],
            selectedItem: undefined,
            search: "",
            page: 0,
            loadMore: true,
            onRequest: false,
            showOption: false,
            options: [],
            offBaseSelectClickEvent: () => {
            },
            searchTimeoutFn: 0,
        };
    },
    async mounted() {
        this.offBaseSelectClickEvent = this.pubSub.on(this.EventNames.BaseSelectClick, (val) => {
            if (val == this)
                return;
            this.showOption = false;
        });
        this.options = this.items;
        window.addEventListener("click", this.onWindowClick);
        await this.$nextTick();
        this.initSelectedItem();
    },
    beforeUnmount() {
        window.removeEventListener("click", this.onWindowClick);
        this.offBaseSelectClickEvent();
        this.$emit("update:modelValue", "");
    },
    methods: {
        reset() {
            var _a;
            this.search = "";
            this.loadMore = true;
            this.selectedItem = undefined;
            this.options = (_a = this.items) !== null && _a !== void 0 ? _a : [];
        },
        async removeFromSelected(index) {
            if (Array.isArray(this.selectedItem)) {
                const item = { ...this.selectedItem[index] };
                this.selectedItem.splice(index, 1);
                this.$emit("update:modelValue", this.selectedItem.map((item) => item[this.keyValue]));
                await this.$nextTick();
                if (this.removedSelectedItem) {
                    this.removedSelectedItem(item);
                }
            }
        },
        isItemActive(item) {
            var _a;
            return ((this.selectedItem &&
                ((_a = this.selectedItem) === null || _a === void 0 ? void 0 : _a[this.keyValue]) == item[this.keyValue]) ||
                (Array.isArray(this.selectedItem) &&
                    this.selectedItem.find((currItem) => currItem[this.keyValue] === item[this.keyValue])));
        },
        setSelectedItemFromArray(arrs) {
            var _a;
            this.selectedItem = [];
            for (let i = 0; (_a = i < arrs.length) !== null && _a !== void 0 ? _a : []; i++) {
                const obj = {};
                obj[this.keyText] = arrs[i][this.keyText];
                obj[this.keyValue] = arrs[i][this.keyValue];
                this.selectedItem.push(obj);
                this.$emit("update:modelValue", this.selectedItem.map((item) => item[this.keyValue]));
            }
        },
        /**
         * menampilkan text yang terpilih di select
         * berdasarkan nilai modelValue */
        initSelectedItem() {
            if (this.multiple && !Array.isArray(this.modelValue)) {
                toast("error", "the model of base select must be an array if multiple props active", 0);
                return console.error("the model of base select must be an array if multiple props active");
            }
            if (typeof this.modelValue === "object") {
                if (Array.isArray(this.modelValue)) {
                    this.setSelectedItemFromArray([...this.modelValue]);
                }
                else {
                    this.selectedItem = { ...this.modelValue };
                    // this.choose(this.modelValue as any)
                }
            }
            else {
                this.selectedItem = this.options.find((item) => item[this.keyValue] === this.modelValue);
            }
        },
        onItemScroll(e) {
            if (e.target instanceof HTMLElement) {
                if (e.target.scrollTop >=
                    e.target.scrollHeight - e.target.clientHeight) {
                    this.paginateRequest();
                }
            }
        },
        async paginateRequest(reset = false) {
            if (this.resourceHttp != null && this.loadMore && !this.onRequest) {
                this.onRequest = true;
                this.page++;
                if (reset)
                    this.page = 1;
                this.tmpItems = [];
                const { data, has_more } = await this.resourceHttp.paginate({
                    ...this.filterPaginate,
                    search: this.search,
                    page: this.page,
                });
                this.options = !reset ? [...this.options, ...data] : data;
                this.tmpItems = this.options;
                this.loadMore = has_more;
                this.onRequest = false;
            }
        },
        show() {
            var _a;
            if (this.disabled || this.readonly)
                return;
            this.search = "";
            this.showOption = !this.showOption;
            this.showOption
                ? this.pubSub.trigger(EventNames.BaseSelectClick, this)
                : "";
            this.options = (_a = this.items) !== null && _a !== void 0 ? _a : [];
            this.paginateRequest();
        },
        onWindowClick() {
            this.showOption = false;
        },
        removeItem() {
            this.$emit("update:modelValue", "");
            this.selectedItem = undefined;
        },
        async choose(item) {
            this.showOption = false;
            if (this.multiple && Array.isArray(this.modelValue)) {
                if (!Array.isArray(this.selectedItem)) {
                    this.selectedItem = [];
                }
                this.selectedItem.push(item);
                await this.$nextTick();
                this.$emit("update:modelValue", this.selectedItem.map((item) => item[this.keyValue]));
            }
            else if (!this.multiple) {
                this.selectedItem = { ...item };
                await this.$nextTick();
                this.$emit("update:modelValue", item[this.keyValue]);
                this.$emit("change", item);
            }
        },
    },
    watch: {
        showOption(val) {
            if (!val) {
                this.options = [];
                this.loadMore = true;
                this.page = 0;
            }
        },
        search() {
            clearTimeout(this.searchTimeoutFn);
            this.searchTimeoutFn = setTimeout(() => {
                this.loadMore = true;
                this.paginateRequest(true);
            }, 300);
        },
        items: {
            deep: true,
            handler(arrs) {
                this.options = arrs;
            },
        },
        modelValue: {
            immediate: true,
            async handler(val) {
                if (!val || (Array.isArray(val) && val.length === 0)) {
                    this.selectedItem = undefined;
                }
                else if (!this.multiple) {
                    if (this.resourceHttp) {
                        if (this.tmpItems.length) {
                            this.selectedItem = this.tmpItems.find((item) => item[this.keyValue] === val);
                        }
                    }
                    else if (!val) {
                        this.selectedItem = this.items.find((item) => item[this.keyValue] === val);
                    }
                    if (typeof val === 'object') {
                        await this.choose(val);
                    }
                }
            },
        }
    },
});
