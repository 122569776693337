import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import { baseModal, crewStatus, helperMixin, userProfilePDF } from "@/mixins";
import { defineComponent } from "vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { Default } from "@/enums";
export default defineComponent({
    props: {
        crew: {
            type: Object,
            required: true
        },
    },
    mixins: [helperMixin, baseModal, crewStatus, userProfilePDF],
    components: {
        BaseModal,
        BaseButtonDefault,
        BaseButtonDanger,
        BaseButtonPrimary,
    },
    computed: {
        avatar() {
            return this.crew.user.avatar_link || Default.image;
        },
        Default() {
            return Default;
        },
        pdfFilename() {
            return `${this.crew.crew_id}_${this.crew.user.fullname}`;
        }
    }
});
