import { RouteNames } from "@/enums";
import { RouterView } from "vue-router";
export default {
    path: "/crew",
    component: RouterView,
    redirect: {
        name: RouteNames.Crew,
    },
    meta: {
        rootName: RouteNames.Crew,
        withContentFooter: true,
    },
    children: [
        {
            path: "",
            name: RouteNames.Crew,
            component: () => import("@/pages/Admin/Crew/Index.vue"),
            meta: {
                withContentTools: true,
                withFilter: true
            }
        },
        {
            path: ":id",
            name: RouteNames.CrewDetail,
            component: () => import("@/pages/Superadmin/Sailor/Detail.vue"),
            meta: {
                breadcrumbs: ["detail"],
                withBackButton: true
            }
        }
    ]
};
