import { filterHelper, helperMixin, infinityScrollTable, positionFilter, } from "@/mixins";
import { defineComponent } from "vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import subscriptionPlanHttp from "@/http/subscriptionPlan";
export default defineComponent({
    components: {
        ActionTableColumn,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        ConfirmModal,
    },
    mixins: [
        helperMixin,
        filterHelper(),
        positionFilter(),
        infinityScrollTable(subscriptionPlanHttp()),
    ],
});
