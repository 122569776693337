import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import companyHttp from "@/http/company";
import { toast } from "@/template/app";
import AvatarImg from "@/components/AvatarImg.vue";
import CompanyDetailJob from "@/components/CompanyDetail/CompanyDetailJob.vue";
import CompanyDetailPermission from "@/components/CompanyDetail/CompanyDetailPermission.vue";
import CompanyDetailCrew from "@/components/CompanyDetail/CompanyDetailCrew.vue";
import CompanyDetailFleet from "@/components/CompanyDetail/CompanyDetailFleet.vue";
import CompanyDetailSubscription from "@/components/CompanyDetail/CompanyDetailSubscription.vue";
import LoadingPage from "@/_Root.vue";
export default defineComponent({
    components: {
        LoadingPage,
        CompanyDetailSubscription,
        AvatarImg,
        CompanyDetailJob,
        CompanyDetailPermission,
        CompanyDetailCrew,
        CompanyDetailFleet,
    },
    mixins: [helperMixin],
    data: () => {
        return {
            company: {},
        };
    },
    computed: {
        companyHttp,
    },
    mounted() {
        this.fetch();
    },
    methods: {
        async fetch() {
            var _a, _b;
            const { status, message, response } = await this.companyHttp.read((_a = this.currentRoute.params) === null || _a === void 0 ? void 0 : _a.id);
            if (status === 200) {
                this.company = response.data;
            }
            else if (status === 404) {
                this.useRouter().push({
                    name: (_b = this.currentRoute.meta) === null || _b === void 0 ? void 0 : _b.rootName,
                });
            }
            else {
                toast("error", message, 0);
            }
        },
    },
});
