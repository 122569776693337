import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import fleetHttp from "@/http/fleet";
import { baseModal, crewStatus, filterHelper, helperMixin, infinityScrollTable, positionFilter } from "@/mixins";
import { defineComponent } from "vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import { CrewStatus, Readliness } from "@/enums";
import { crewHttp } from "@/http/crew";
import selectionStore from "@/store/selection";
import FilterModalContent from "@/components/FilterModalContent.vue";
import contentTool from "@/store/contentTool";
import crewBoardHttp from "@/http/crewBoard";
import { toast } from "@/template/app";
export default defineComponent({
    emits: ["saved"],
    mixins: [helperMixin, baseModal, crewStatus, filterHelper(),
        positionFilter(),
        infinityScrollTable(crewHttp(), {
            position_in: [],
            status: CrewStatus.StandBy
        })],
    props: {
        fleet: {
            type: Object
        }
    },
    computed: {
        contentTool,
        selectionStore,
        Readliness() {
            return Readliness;
        },
        CrewStatus() {
            return CrewStatus;
        },
        fleetHttp,
    },
    components: {
        FilterModalContent,
        AvatarImg, BaseTableRow, BaseCheckbox, BaseTable, BaseBadge, BaseTableColumn,
        BaseModal,
        BaseButtonDefault,
        BaseButtonDanger,
        BaseButtonPrimary,
        BaseFormGroup,
    },
    data() {
        return {
            submitting: false,
            form: {
                fleet_id: "",
                crews: [],
                sign_on: "",
                sign_off: ""
            },
        };
    },
    methods: {
        onMounted() {
            this.filter.status = CrewStatus.StandBy;
        },
        setForm() {
            var _a;
            this.filter.status = CrewStatus.StandBy;
            this.reload();
            this.validationErrors = {};
            this.form.fleet_id = (_a = this.fleet) === null || _a === void 0 ? void 0 : _a.id;
            this.form.crews = [];
            this.form.sign_on = "";
            this.form.sign_off = "";
        },
        async submit() {
            this.submitting = true;
            const { response, status, message, validationErrors } = await crewBoardHttp().multiSave(this.form);
            this.submitting = false;
            if (status == 200) {
                toast("success", message);
                this.$emit("saved", response);
            }
            else if (status == 422) {
                this.validationErrors = validationErrors;
            }
            else {
                toast("error", message, 0);
            }
        }
    }
});
