import { defineComponent, ref } from "vue";
import { helperMixin } from "@/mixins";
import DashboardBox from "@/components/Dashboard/DashboardBox.vue";
import jobHttp from "@/http/job";
import companyHttp from "@/http/company";
import sailorHttp from "@/http/sailor";
import LineChart from "@/components/Dashboard/LineChart.vue";
// import { Chart, registerables } from "chart.js";
export default defineComponent({
    components: { DashboardBox, LineChart },
    mixins: [helperMixin],
    setup() {
        return {
            registeredCompanyChart: ref(null),
            registeredSailorChart: ref(null),
            postedJobChart: ref(null),
        };
    },
    data: () => {
        return {
            companyCount: 0,
            sailorCount: 0,
        };
    },
    computed: {
        jobHttp,
        companyHttp,
        sailorHttp,
    },
    mounted() {
        this.getCounts();
    },
    methods: {
        async getCounts() {
            this.http.request("GET", "/company/count").then((data) => {
                this.companyCount = data.response.data;
            });
            this.http.request("GET", "/sailor/count").then((data) => {
                this.sailorCount = data.response.data;
            });
        },
    },
    // async mounted() {
    //   Chart.register(...registerables);
    //   await this.$nextTick();
    //   this.initRegisteredCompanyChart();
    //   this.initRegisteredSailorChart();
    //   this.initPostedJobChart();
    // },
    // methods: {
    //   buildChart(
    //     title: string,
    //     el: HTMLCanvasElement,
    //     data: { [key: string]: number }
    //   ) {
    //     const labels: string[] = [];
    //     const counts: number[] = [];
    //     for (let key in data) {
    //       labels.push(key);
    //       counts.push(data[key]);
    //     }
    //     new Chart(el, {
    //       type: "line",
    //       data: {
    //         labels,
    //         datasets: [
    //           {
    //             label: title,
    //             backgroundColor: "#0095da",
    //             borderColor: "#4ac6ff",
    //             data: counts
    //           }
    //         ]
    //       },
    //       options: {}
    //     });
    //   },
    //   async initPostedJobChart() {
    //     this.buildChart(
    //       this.trans("layout.posted_job_chart_title"),
    //       this.postedJobChart,
    //       await this.jobHttp.groupByMonth()
    //     );
    //   },
    //   async initRegisteredCompanyChart() {
    //     this.buildChart(
    //       this.trans("layout.registered_company_chart_title"),
    //       this.registeredCompanyChart,
    //       await this.companyHttp.groupByMonth()
    //     );
    //   },
    //   async initRegisteredSailorChart() {
    //     this.buildChart(
    //       this.trans("layout.registered_sailor_chart_title"),
    //       this.registeredSailorChart,
    //       await this.sailorHttp.groupByMonth()
    //     );
    //   }
    // }
});
