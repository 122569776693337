import app from "@/store/app";
export default class CustomNotification extends Notification {
    constructor(title, options) {
        if (Notification.permission === "default") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    window.location.reload();
                }
            });
        }
        else {
            super(title, { ...options, lang: app().state.lang });
        }
    }
}
