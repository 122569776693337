import app from "@/store/app";
import { authStore } from "@/store/auth";
import numeral from "numeral";
import { hideLoading, showLoading, toast } from "@/template/app";
import Http from "@/lib/http";
export const replaceWithSpace = (val, delimiter = "_") => {
    return val.replace(new RegExp(`\\${delimiter}`, "g"), " ");
};
export const capitalize = (val) => {
    if (!val)
        return "";
    let text = "";
    const splitVal = val.split(" ");
    for (let i = 0; i < splitVal.length; i++) {
        text += " " + makeFirstCapital(splitVal[i]);
    }
    return text.trim();
};
export const makeFirstCapital = (val) => {
    return val.charAt(0).toUpperCase() + val.substring(1);
};
export const randomString = () => {
    return Math.random().toString(36).substr(2);
};
export const trans = (key, replace, obj = null, realKey) => {
    const o = Object(obj !== null && obj !== void 0 ? obj : app().state.translations);
    let keys = key.split(".");
    if (!o[keys[0]])
        return realKey || "";
    // found!
    if (typeof o[keys[0]] === "string") {
        let str = o[keys[0]];
        for (let replaceKey in replace) {
            str = str.replace(`:${replaceKey}`, replace[replaceKey] || "");
        }
        return makeFirstCapital(str);
    }
    else {
    }
    // recursion until found
    return trans(keys.slice(1).join("."), replace, o[keys[0]], realKey || key);
};
export const goTo = (name, opt) => {
    return {
        name,
        ...opt,
    };
};
export const unformatNumber = (val) => {
    return parseInt(String(val).replace(/\./g, ""));
};
export const numberFormat = (arg) => {
    if (arg instanceof Event) {
        const target = arg.target;
        if (!Number.isInteger(parseInt(arg.key))) {
            target.value = String(target.value).replace(/[^0-9]/g, "");
        }
        if (target.value) {
            target.value = String(numeral(unformatNumber(target.value)).format("0,0")).replace(/\,/g, ".");
        }
    }
    else if (arg) {
        return String(numeral(unformatNumber(arg)).format("0,0")).replace(/\,/g, ".");
    }
};
export const indexOfClass = (collections, node) => {
    if (!collections)
        return -1;
    for (let i = 0; i < collections.length; i++) {
        if (collections[i] == node) {
            return i;
        }
    }
    return -1;
};
export const checkPermissionMenu = (menu) => {
    var _a, _b, _c;
    return (menu.permission ||
        ((_b = (_a = menu.parent) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.permission) ||
        menu.is_setting_menu ||
        ((_c = menu.access) === null || _c === void 0 ? void 0 : _c.permission));
};
export const isUnpaidPaymentAvailable = () => {
    var _a, _b, _c, _d;
    return !!((_d = (_c = (_b = (_a = authStore().state.user) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.subscription) === null || _c === void 0 ? void 0 : _c.unpaid_payment) === null || _d === void 0 ? void 0 : _d.id);
};
export const formatDate = (val, withTime = false) => {
    var _a, _b, _c, _d;
    if (!val)
        return "";
    const padWithZero = (value) => {
        return value < 10 ? "0" + value : value;
    };
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21)
            return "th";
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };
    try {
        val.split("T");
    }
    catch (error) {
        return val;
    }
    const valsplit = val.split("T");
    const arr = (valsplit[0] + (valsplit[1] ? " " + ((_a = valsplit[1]) === null || _a === void 0 ? void 0 : _a.substr(0, 5)) : "")).split(/[- :]/);
    const date = new Date(arr[0], arr[1] - 1, arr[2], (_b = arr[3]) !== null && _b !== void 0 ? _b : null, (_c = arr[4]) !== null && _c !== void 0 ? _c : null, (_d = arr[5]) !== null && _d !== void 0 ? _d : null);
    if (date.toString() === "Invalid Date") {
        return val;
    }
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const formattedDate = `${month} ${day}${ordinalSuffix}, ${year}`;
    return withTime ? `${formattedDate} ${hours}:${minutes}` : formattedDate;
};
export const calculateAge = (val) => {
    var _a, _b, _c, _d;
    if (!val)
        return "";
    const padWithZero = (value) => {
        return value < 10 ? "0" + value : value;
    };
    try {
        val.split("T");
    }
    catch (error) {
        return val;
    }
    const valsplit = val.split("T");
    const arr = (valsplit[0] + (valsplit[1] ? " " + ((_a = valsplit[1]) === null || _a === void 0 ? void 0 : _a.substr(0, 5)) : "")).split(/[- :]/);
    const date = new Date(arr[0], arr[1] - 1, arr[2], (_b = arr[3]) !== null && _b !== void 0 ? _b : null, (_c = arr[4]) !== null && _c !== void 0 ? _c : null, (_d = arr[5]) !== null && _d !== void 0 ? _d : null);
    if (isNaN(date.getTime())) {
        return "Invalid date";
    }
    const now = new Date();
    const age = now.getFullYear() - date.getFullYear();
    const monthDiff = now.getMonth() - date.getMonth();
    const dayDiff = now.getDate() - date.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        // Adjust age if the birthday hasn't occurred yet this year
        return `${age - 1}`;
    }
    return age;
};
export const currentCompany = () => {
    return authStore().state.user.company;
};
export const currentSubscription = () => {
    var _a, _b, _c;
    return ((_c = (_b = (_a = authStore().state.user) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.subscription) !== null && _c !== void 0 ? _c : {});
};
export const unpaidPayment = () => {
    var _a;
    return ((_a = currentSubscription().unpaid_payment) !== null && _a !== void 0 ? _a : {});
};
export const recentPayment = () => {
    var _a;
    return ((_a = currentSubscription().recent_payment) !== null && _a !== void 0 ? _a : {});
};
export const emptyString = (val) => {
    return val ? val : "-";
};
export const downloadFile = async (downloadLink, name) => {
    try {
        showLoading();
        const { message, response, status, headers } = await new Http().request("GET", downloadLink, {
            responseType: "arraybuffer",
        });
        hideLoading();
        if (status === 404) {
            throw "FILE NOT FOUND";
        }
        else if (status === 500) {
            throw "INTERNAL SERVER ERROR";
        }
        else if (status === 403) {
            throw "FORBIDDEN ACCESS";
        }
        const blob = new Blob([response], { type: headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        window.URL.revokeObjectURL(link.href);
        // hideLoading();
    }
    catch (error) {
        toast("error", error, 0);
    }
};
export const setPageTitle = (title) => {
    document.title = title
        ? title + " | " + process.env.VUE_APP_NAME
        : process.env.VUE_APP_NAME;
};
export const fullname = (user) => {
    return `${user.first_name} ${user.last_name}`;
};
export const getChatFileIcon = (type) => {
    return type == 'image' ? 'mdi mdi-image' : 'mdi mdi-file-document';
};
