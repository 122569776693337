import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";
export default createBaseChildrenRecordRaw("sailor", {
    index: {
        name: RouteNames.Sailor,
        component: () => import("@/pages/Superadmin/Sailor/Index.vue"),
        meta: {
            withContentTools: true,
            rootName: RouteNames.Sailor,
        },
    },
    create: {
        name: RouteNames.SailorCreate,
        component: () => import("@/pages/Superadmin/Sailor/MultiSave.vue"),
    },
    edit: {
        name: RouteNames.SailorEdit,
        component: () => import("@/pages/Superadmin/Sailor/Edit.vue"),
        meta: {
            withContentFooter: true,
        }
    },
    detail: {
        name: RouteNames.SailorDetail,
        component: () => import("@/pages/Superadmin/Sailor/Detail.vue"),
        meta: {
            withContentFooter: true,
            rootName: RouteNames.Sailor,
        },
    },
});
