import { defineComponent, watch } from "vue";
import { helperMixin, selectionDataProp } from "@/mixins";
import CheckboxActionTool from "@/components/CheckboxActionTool.vue";
export default defineComponent({
    components: {
        CheckboxActionTool,
    },
    mixins: [helperMixin, selectionDataProp],
    emits: ["add-item", "edit", "delete"],
    props: {
        modelValue: {
            type: Array,
            default: [],
        },
        parentIndex: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            isChecked: false,
            hide: false,
        };
    },
    computed: {
        availableToCRUD() {
            var _a;
            return !((_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.is_default) || this.parentIndex < 2;
        },
        index() {
            var _a;
            return this.modelValue.indexOf((_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.id);
        },
        isAlreadyChecked() {
            return this.index !== -1;
        },
        valuable() {
            return !this.childs.length;
        },
    },
    mounted() {
        this.setup();
        watch(() => this.isChecked, this.handleChange);
    },
    methods: {
        edit() {
            var _a;
            this.$emit("edit", (_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.id);
        },
        remove() {
            var _a, _b, _c;
            this.$emit("delete", {
                id: (_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.id,
                parentId: (_b = this.selectionData) === null || _b === void 0 ? void 0 : _b.parent_id,
                name: (_c = this.selectionData) === null || _c === void 0 ? void 0 : _c.name,
            });
        },
        addItem() {
            var _a;
            this.$emit("add-item", (_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.id);
        },
        setup() {
            this.$nextTick(() => {
                var _a;
                if (this.valuable) {
                    if (this.isAlreadyChecked) {
                        this.isChecked = true;
                        !this.$parent.isChecked && this.$parent.recursiveChecked
                            ? this.$parent.recursiveChecked()
                            : "";
                    }
                }
                if ((_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.parent_id) {
                    this.hide = !this.isChecked && !this.$parent.isChecked;
                }
            });
        },
        childRefKey(child) {
            return `child-${child.id}`;
        },
        handleChange(isChecked) {
            var _a;
            if (this.childs.length) {
                this.recursiveHide();
            }
            else if (isChecked) {
                if (!this.isAlreadyChecked)
                    this.modelValue.push((_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.id);
            }
            else {
                this.modelValue.splice(this.index, 1);
            }
        },
        recursiveChecked() {
            if (this.isChecked)
                return;
            this.isChecked = true;
            this.handleChange(true);
            this.$nextTick(() => {
                if (this.$parent.recursiveChecked)
                    this.$parent.recursiveChecked();
            });
        },
        recursiveHide() {
            var _a;
            for (let i = 0; i < this.childs.length; i++) {
                const refChild = (_a = this.$refs[this.childRefKey(this.childs[i])]) === null || _a === void 0 ? void 0 : _a[0];
                if (!refChild)
                    continue;
                refChild.hide = !this.isChecked;
                if (!this.isChecked && refChild.isChecked) {
                    refChild.isChecked = false;
                    if (refChild.index !== -1) {
                        this.modelValue.splice(refChild.index, 1);
                    }
                }
                refChild.$nextTick(() => {
                    if (refChild.childs.length)
                        refChild.recursiveHide();
                });
            }
        },
        isChildChecked(childs) {
            var _a;
            for (let i = 0; i < childs.length; i++) {
                const refChild = (_a = this.$refs[this.childRefKey(childs[i])]) === null || _a === void 0 ? void 0 : _a[0];
                if (refChild.isChecked) {
                    return true;
                }
                if (childs[i].recursive_childs) {
                    return this.isChildChecked(childs[i].recursive_childs);
                }
            }
        },
    },
    watch: {
        selectionData() {
            this.$nextTick(() => {
                this.isChecked =
                    this.index !== -1 ||
                        this.isChildChecked(this.childs);
            });
        },
    },
});
