import Http from "@/lib/http";
export default class BaseResourceHttp extends Http {
    constructor() {
        super();
        this.validationErrors = {};
        this.ignoreMethods = [];
        // return new Proxy(this, {
        //   get: function (self, key: any) {
        //     if (
        //       typeof self[key] === "function" &&
        //       self.ignoreMethods.includes(key)
        //     ) {
        //       throw new Error(`method ${key} is not implemented`);
        //     }
        //
        //     return key in self
        //       ? self[key]
        //       : console.log("Access to non-existent property '" + key + "'");
        //   },
        // });
    }
    url(path) {
        return `${this.baseURL()}/${path}`;
    }
    async count() {
        const { response } = await this.request("GET", this.url("count"));
        return response.data || 0;
    }
    async fetchAll() {
        var _a;
        const { response, status, message } = await this.request("GET", this.url("all"), {});
        return {
            message,
            status,
            data: (_a = response.data) !== null && _a !== void 0 ? _a : [],
        };
    }
    async paginate(filter) {
        var _a;
        const { response, status, message } = await this.request("GET", this.baseURL(), {
            params: filter,
        });
        return {
            message,
            status,
            data: (_a = response.data) !== null && _a !== void 0 ? _a : [],
            has_more: response.has_more,
            total: response.total,
        };
    }
    async chart(filter) {
        var _a;
        const { response } = await this.request("GET", this.url("chart"), {
            params: {
                ...filter,
            },
        });
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
    create(data) {
        return this.request("POST", this.baseURL(), {
            data,
        });
    }
    read(id) {
        return this.request("GET", `${this.baseURL()}/${id}`);
    }
    update(data, id) {
        return this.request("PUT", `${this.baseURL()}/${id}`, {
            data,
        });
    }
    delete(id) {
        return this.request("DELETE", `${this.baseURL()}/${id}`);
    }
}
