import { computed, defineComponent } from "vue";
export default defineComponent({
    props: {
        withBorder: {
            type: Boolean,
            default: false,
        },
        withHover: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const colorClass = computed(() => {
            switch (props.color) {
                case "danger":
                    return "text-red-500";
                case "warning":
                    return "text-yellow-600";
                case "info":
                    return "text-blue-500";
                case "primary":
                    return "text-primary";
                case "success":
                    return "text-green-500";
                default:
                    return "text-deep";
            }
        });
        const borderClass = computed(() => {
            switch (props.color) {
                case "danger":
                    return "border border-red-500";
                case "warning":
                    return "border border-yellow-600";
                case "info":
                    return "border border-blue-500";
                case "primary":
                    return "border border-primary";
                case "success":
                    return "border border-green-500";
                default:
                    return "";
            }
        });
        const hoverClass = computed(() => {
            switch (props.color) {
                case "danger":
                    return "hover:bg-red-500 hover:text-white";
                case "warning":
                    return "hover:bg-yellow-600 hover:text-white";
                case "info":
                    return "hover:bg-blue-500 hover:text-white";
                case "primary":
                    return "hover:bg-primary hover:text-white";
                case "success":
                    return "hover:bg-green-500 hover:text-white";
                default:
                    return "";
            }
        });
        return { colorClass, borderClass, hoverClass };
    },
});
