import { helperMixin } from "@/mixins";
import { defineComponent, ref } from "vue";
import { Chart, registerables } from "chart.js";
import { FilterChartPeriode } from "@/enums";
import moment from "moment";
export default defineComponent({
    mixins: [helperMixin],
    props: {
        resourceHttp: {
            type: Object,
            required: true
        },
        title: {
            type: String
        },
        canvasHeight: {
            type: String,
            default: "0"
        },
        canvasWidth: {
            type: String,
            default: "0"
        }
    },
    setup() {
        return {
            root: ref(null)
        };
    },
    data: () => {
        const now = moment();
        return {
            filterTimeout: 0,
            FilterChartPeriode,
            filter: {
                periode: FilterChartPeriode.day,
                month: parseInt(now.format("MM")),
                year: parseInt(now.format("YYYY"))
            }
        };
    },
    async mounted() {
        Chart.register(...registerables);
        await this.$nextTick();
        this.build();
    },
    methods: {
        async build() {
            var _a;
            (_a = this.root.querySelector("canvas")) === null || _a === void 0 ? void 0 : _a.remove();
            let canvas = document.createElement("canvas");
            if (this.canvasHeight) {
                canvas.setAttribute("height", this.canvasHeight);
            }
            if (this.canvasWidth) {
                canvas.setAttribute("width", this.canvasWidth);
            }
            this.root.appendChild(canvas);
            const data = await this.resourceHttp.chart(this.filter);
            const labels = [];
            const counts = [];
            for (let key in data) {
                labels.push(key);
                counts.push(data[key]);
            }
            new Chart(canvas, {
                type: "line",
                data: {
                    labels,
                    datasets: [
                        {
                            label: this.title,
                            backgroundColor: "#0095da",
                            borderColor: "#4ac6ff",
                            data: counts
                        }
                    ]
                },
                options: {}
            });
        }
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                clearTimeout(this.filterTimeout);
                this.filterTimeout = setTimeout(() => {
                    this.build();
                }, 200);
            }
        }
    }
});
