import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import { baseModal, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
export default defineComponent({
    props: {
        sm: {
            type: Boolean,
            default: false,
        },
        lg: {
            type: Boolean,
            default: false,
        },
        xl: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [helperMixin, baseModal],
    components: {
        BaseModal,
        BaseButtonDefault,
        BaseButtonDanger,
        BaseButtonPrimary,
    },
});
