import { filterHelper, helperMixin, infinityScrollTable, positionFilter } from "@/mixins";
import { defineComponent } from "vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import qnaHttp from "@/http/qna";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import { QnaStatus } from "@/enums";
import { toast } from "@/template/app";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import PreviewModal from "@/components/PreviewModal.vue";
export default defineComponent({
    data: () => ({
        QnaStatus,
        imagePreviewModal: false,
        approveQnaModal: false,
        rejectQnaModal: false,
        rejectReason: ""
    }),
    components: {
        PreviewModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        ActionTableColumn,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        ConfirmModal
    },
    mixins: [
        helperMixin,
        filterHelper(),
        positionFilter(),
        infinityScrollTable(qnaHttp())
    ],
    methods: {
        showPreviewImage(row) {
            this.imagePreviewModal = true;
            this.selectedRow = row;
        },
        showConfirmModal(type, row) {
            if (type === 'approve') {
                this.approveQnaModal = true;
            }
            else if (type === 'reject') {
                this.rejectQnaModal = true;
            }
            this.selectedRow = row;
        },
        async reject() {
            const { message, status, validationErrors } = await qnaHttp().reject(this.selectedRow.id, this.rejectReason);
            if (status === 422) {
                this.validationErrors = validationErrors;
            }
            else if (status !== 200) {
                toast("error", message, 0);
            }
            else {
                toast("success", message);
                this.rejectQnaModal = false;
                this.reload();
            }
        },
        async approve() {
            const { message, status } = await qnaHttp().approve(this.selectedRow.id);
            if (status !== 200) {
                toast("error", message, 0);
                return;
            }
            toast("success", message);
            this.reload();
        },
        statusColor(status) {
            switch (status) {
                case QnaStatus.Approved:
                    return 'text-primary';
                case QnaStatus.NeedApproval:
                    return 'text-yellow-500';
                case QnaStatus.Rejected:
                    return 'text-red-500';
            }
        }
    }
});
