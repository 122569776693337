import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { CrewBoardSaveAct } from "@/enums";
import crewHttp from "@/http/crew";
import crewBoardHttp from "@/http/crewBoard";
import { baseModal, helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: {
        BaseModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        AvatarImg,
        BaseFormApp,
        ConfirmModal,
    },
    mixins: [
        helperMixin,
        baseModal,
        saveAndUpdate(crewBoardHttp()),
    ],
    emits: ["done", "update:crewBoard", "update:modelValue"],
    props: {
        crewBoard: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showConfirmSubmitModal: false,
            crewText: `${this.crewBoard.crew.crew_id} - ${this.crewBoard.crew.user.fullname}`,
        };
    },
    computed: {
        crewHttp,
        updateId() {
            return this.crewBoard.id;
        },
        redirectAfterSubmit() {
            return false;
        },
    },
    methods: {
        checkBeforeSubmit() {
            // jika sudah ada pengganti
            if (this.crewBoard.subtitute) {
                this.showConfirmSubmitModal = true;
            }
            else {
                this.submit();
            }
        },
        afterSubmit() {
            this.$emit("update:crewBoard", null);
            this.$emit("update:modelValue", false);
        },
        onModalHide() {
            this.validationErrors = {};
        },
        setForm() {
            this.form.act = CrewBoardSaveAct.Renew;
            this.form.sign_on = this.crewBoard.sign_on;
            this.form.sign_off = "";
        },
    },
});
