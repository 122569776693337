import { defineComponent, ref } from "vue";
import TopMenuDropdown from "@/components/App/TopMenuDropdown.vue";
import { helperMixin, infinityScrollTable } from "@/mixins";
import CompanyLogo from "@/components/CompanyLogo.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import notificationHttp from "@/http/notification";
import jobApplicantHttp from "@/http/jobApplicant";
import moment from "moment";
import echo from "@/lib/echo";
import CustomNotification from "@/lib/customNotification";
import ProfileDetailModal from "@/components/JobApplicant/ProfileDetailModal.vue";
import { toast } from "@/template/app";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { UserRole } from "@/enums";
import router from "@/lib/router";
export default defineComponent({
    mixins: [
        helperMixin,
        infinityScrollTable(notificationHttp()),
    ],
    components: {
        TopMenuDropdown,
        CompanyLogo,
        BaseLink,
        AvatarImg,
        ProfileDetailModal,
        BaseButtonPrimary,
    },
    setup() {
        return {
            toggler: ref(null),
        };
    },
    data() {
        return {
            selectedJobApplicant: null,
            showProfileDetailModal: false,
            unread: 0,
            channelName: "",
        };
    },
    mounted() {
        this.listenNotification();
    },
    destroyed() {
        echo.leave(this.channelName);
    },
    computed: {
        notificationHttp,
        jobApplicantHttp,
    },
    methods: {
        async onMarkAllAsReadClick() {
            const { status, response } = await this.notificationHttp.markAllAsRead();
            if (status === 200) {
                this.reload();
            }
            else if (status !== 422) {
                toast("error", response.message, 0);
            }
        },
        async onNotificationClick(notification) {
            if (!notification.is_read) {
                const { status } = await this.notificationHttp.markAsRead(notification.id);
                if (status === 200) {
                    notification.is_read = 1;
                    this.unread--;
                }
            }
            if (notification.job_applicant_id) {
                const { response, status } = await jobApplicantHttp().read(notification.job_applicant_id);
                if (status === 200) {
                    this.selectedJobApplicant = response.data;
                    this.showProfileDetailModal = true;
                }
                else {
                    toast("error", response.message, 0);
                }
            }
            if (notification.company_id &&
                this.authStore.state.user.role === UserRole.SuperAdmin) {
                this.appStore.state.loadingRoute = true;
                await this.$nextTick();
                setTimeout(async () => {
                    await router.push({
                        name: this.RouteNames.CompanyDetail,
                        params: {
                            id: notification.company_id,
                        },
                    });
                    this.appStore.state.loadingRoute = false;
                }, 10);
            }
        },
        listenNotification() {
            Notification.requestPermission();
            if (this.authStore.authRoleCheck(this.UserRole.Admin) &&
                this.authStore.state.user.company) {
                this.channelName = `admin-notification.${this.authStore.state.user.company.id}`;
            }
            else if (this.authStore.authRoleCheck(this.UserRole.SuperAdmin)) {
                this.channelName = `superadmin-notification`;
            }
            else {
                toast("warning", "invalid role for listening the notification", 0);
                throw new Error("invalid role for listening the notification");
            }
            echo.private(this.channelName).listen(".save", (data) => {
                this.showProfileDetailModal = false;
                this.reload();
                this.pubSub.trigger(this.EventNames.IncomingApplication);
                new CustomNotification(this.makeFirstCapital(data.title), {
                    body: data.message,
                }).addEventListener("click", () => {
                    window.focus();
                    this.toggler.click();
                });
            });
        },
        onFetchSuccess(data) {
            this.unread = data.unread;
        },
        getTime(notification) {
            return moment(notification.created_at)
                .locale(this.appStore.state.lang)
                .fromNow();
        },
    },
});
