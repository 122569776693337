import { helperMixin, selectionDataHelper, uwuLoaderDelay } from "@/mixins";
import { defineComponent, watch } from "vue";
import selectionStore from "@/store/selection";
import SelectionDataList from "@/components/Job/SelectionDataList.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
export default defineComponent({
    components: { SelectionDataList, CompanyLogo },
    mixins: [helperMixin, selectionDataHelper, uwuLoaderDelay],
    props: {
        fetchSelection: {
            type: Boolean,
            default: false
        },
        job: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            requirements: []
        };
    },
    computed: {
        selectionStore,
        orderedRequirements() {
            return this.requirements.sort(this.compareSelectionOrder);
        },
        benefits() {
            return this.job.benefits
                ? this.job.benefits
                    .map(id => this.selectionStore.findById(id, this.selectionStore.state.job_benefit))
                    .sort(this.compareSelectionOrder)
                : [];
        },
        positionName() {
            var _a;
            return (_a = this.selectionStore.findById(this.job.position_id, this.selectionStore.state.job_position)) === null || _a === void 0 ? void 0 : _a.name;
        },
        currency() {
            var _a;
            return (_a = this.selectionStore.findById(this.job.salary_currency_id, this.selectionStore.state.currency)) === null || _a === void 0 ? void 0 : _a.name;
        },
        salary() {
            return this.numberFormat(this.job.salary_rate);
        },
        salaryType() {
            var _a;
            return (_a = this.selectionStore.findById(this.job.salary_type_id, this.selectionStore.state.job_salary_type)) === null || _a === void 0 ? void 0 : _a.name;
        },
        contractOnType() {
            var _a;
            return (_a = this.selectionStore.findById(this.job.contract_on_type_id, this.selectionStore.state.job_contract_type)) === null || _a === void 0 ? void 0 : _a.name;
        },
        contractOffType() {
            var _a;
            return (_a = this.selectionStore.findById(this.job.contract_off_type_id, this.selectionStore.state.job_contract_type)) === null || _a === void 0 ? void 0 : _a.name;
        }
    },
    async mounted() {
        if (this.fetchSelection)
            await this.selectionStore.fetch();
        watch(() => this.job.requirements, this.setupRequirements, {
            deep: true,
            immediate: true
        });
    },
    methods: {
        setupRequirements() {
            var _a, _b, _c;
            for (let i = 0; i != (((_a = this.job.requirements) === null || _a === void 0 ? void 0 : _a.length) || 0); i++) {
                const requirement = this.selectionStore.findById(this.job.requirements[i], this.selectionStore.state.job_requirement);
                if (!requirement) {
                    continue;
                }
                this.pushIntoRequirement({ ...requirement });
            }
            if (!((_c = (_b = this.job) === null || _b === void 0 ? void 0 : _b.requirements) === null || _c === void 0 ? void 0 : _c.length)) {
                this.requirements = [];
            }
        },
        pushIntoRequirement(requirement) {
            var _a, _b;
            let parent;
            if (!requirement.parent_id) {
                parent = this.requirements;
            }
            else {
                let parentData = {
                    ...this.selectionStore.findById(requirement.parent_id, this.selectionStore.state.job_requirement)
                };
                if (parentData.id) {
                    parentData.recursive_childs = [];
                    parent = (_a = this.pushIntoRequirement(parentData)) === null || _a === void 0 ? void 0 : _a.recursive_childs;
                }
            }
            let i = (_b = parent === null || parent === void 0 ? void 0 : parent.findIndex(d => d.id == requirement.id)) !== null && _b !== void 0 ? _b : 0;
            if (parent) {
                if (i === -1) {
                    i = parent.push(requirement) - 1;
                }
                return parent[i];
            }
        }
    }
});
