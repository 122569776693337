import { defineComponent } from "vue";
import { Default } from "@/enums";
export default defineComponent({
    props: {
        src: String,
        sm: {
            type: Boolean,
            default: false
        },
        lg: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        imgSrc() {
            return this.src || Default.image;
        }
    }
});
