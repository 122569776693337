import BaseLink from "@/components/Base/BaseLink.vue";
import { helperMixin } from "@/mixins";
import { Sidebar, toast } from "@/template/app";
import { defineComponent } from "vue";
import { checkPermissionMenu } from "@/helpers";
export default defineComponent({
    mixins: [helperMixin],
    props: {
        rootMenuPermission: Number,
        menu: {
            type: Object,
            required: true,
        },
    },
    components: { BaseLink },
    computed: {
        hide() {
            var _a;
            return !(((_a = this.appStore.state.activeMenu) === null || _a === void 0 ? void 0 : _a.is_setting_menu) ==
                this.menu.is_setting_menu);
        },
        isActive() {
            var _a;
            return (((_a = this.appStore.state.activeMenu) === null || _a === void 0 ? void 0 : _a.vue_router_name) ===
                this.menu.vue_router_name);
        },
        disabled() {
            var _a;
            return (this.appStore.state.loadingRoute ||
                ((_a = this.appStore.state.activeMenu) === null || _a === void 0 ? void 0 : _a.title) === this.menu.title ||
                !checkPermissionMenu(this.menu));
        },
    },
    data() {
        return {
            Sidebar,
        };
    },
    methods: {
        setActiveMenu() {
            var _a;
            if (!checkPermissionMenu(this.menu)) {
                toast("warning", this.trans("layout.access_menu_denied", {
                    menuName: this.trans(`menu.${(_a = this.menu.parent_title) !== null && _a !== void 0 ? _a : this.menu.title}`),
                }), 0);
                return;
            }
            this.appStore.state.activeMenu = this.menu;
        },
    },
    watch: {
        isActive: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.Sidebar.removeParentMenuOpenActive();
                    this.$nextTick(() => {
                        // if (this.appStore.state.activeMenu) {
                        //   this.appStore.state.activeMenu.permission = checkPermissionMenu(
                        //     this.menu
                        //   )
                        //     ? 1
                        //     : 0;
                        // }
                        this.Sidebar.initActiveMenu();
                    });
                }
            },
        },
    },
});
