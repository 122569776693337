import { RouteNames } from "@/enums";
import { RouterView } from "vue-router";
export default {
    path: 'job_applicant',
    component: RouterView,
    children: [
        {
            path: "",
            redirect: {
                name: RouteNames.JobApplicantInProcess
            },
        },
        {
            path: "in_process",
            name: RouteNames.JobApplicantInProcess,
            component: () => import("@/pages/Admin/JobApplicant/InProcess.vue"),
            meta: {
                withContentTools: true,
                withContentFooter: true
            }
        },
        {
            path: "interview",
            name: RouteNames.JobApplicantInterview,
            component: () => import("@/pages/Admin/JobApplicant/Interview.vue"),
            meta: {
                withContentTools: true
            }
        }
    ]
};
