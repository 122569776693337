import Dropdown from "@/components/Dropdown/Dropdown.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import DropdownMenu from "@/components/Dropdown/DropdownMenu.vue";
import DropdownToggler from "@/components/Dropdown/DropdownToggler.vue";
import { CrewStatus, EventNames, JobApplicantInterviewAct, JobApplicantRescheduleAct, JobApplicantStatus, RouteNames, UserRole, } from "@/enums";
import authFacade from "@/facades/auth";
import { capitalize, formatDate, fullname, goTo, makeFirstCapital, numberFormat, randomString, replaceWithSpace, setPageTitle, trans, } from "@/helpers";
import authHttp from "@/http/auth";
import jobApplicantHttp from "@/http/jobApplicant";
import { createInstance } from "@/lib/fn";
import http from "@/lib/http";
import PubSub from "@/lib/pubSub";
import { useRouter } from "@/lib/router";
import appStore from "@/store/app";
import authStore from "@/store/auth";
import contentTool from "@/store/contentTool";
import selectionStore from "@/store/selection";
import { Layout, offContentBodyScroll, onContentBodyScroll, toast, } from "@/template/app";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { defineComponent, watch } from "vue";
export const interviewResponse = defineComponent({
    computed: {
        jobApplicantHttp,
        pubSub: createInstance(PubSub),
    },
    data() {
        return {
            JobApplicantInterviewAct,
            selectedJobApplicant: false,
            acceptInterviewModal: false,
            rejectInterviewModal: false,
        };
    },
    methods: {
        rejectInterview(row) {
            this.selectedJobApplicant = row;
            this.rejectInterviewModal = true;
        },
        acceptInterview(row) {
            this.selectedJobApplicant = row;
            this.acceptInterviewModal = true;
        },
        async requestHandler(request) {
            console.error("request handler method not implement");
            return Promise.reject();
        },
        // ini method di "infinityScroll"
        reload() {
            return console.error("reload method not implement");
        },
        onInterviewResponseSuccess(act) {
            this.reload();
        },
        /**
         * hire / reject interview berdasarkan data id selectedjobapplicant
         */
        async interviewResponse(id, act) {
            if (await this.requestHandler(this.jobApplicantHttp.acceptOrReject(id, act))) {
                this.pubSub.trigger(EventNames.InterviewRespond);
                this.onInterviewResponseSuccess(act);
            }
        },
    },
});
export const rescheduleResponse = defineComponent({
    computed: {
        jobApplicantHttp,
        pubSub: createInstance(PubSub),
    },
    data() {
        return {
            JobApplicantRescheduleAct,
            selectedJobApplicant: null,
            acceptRescheduleModal: false,
            rejectRescheduleModal: false,
        };
    },
    methods: {
        rejectReschedule(row) {
            this.selectedJobApplicant = row;
            this.rejectRescheduleModal = true;
        },
        acceptReschedule(row) {
            this.selectedJobApplicant = row;
            this.acceptRescheduleModal = true;
        },
        async requestHandler(request) {
            console.error("request handler method not implement");
            return Promise.reject();
        },
        // ini method di "infinityScroll"
        reload() {
            return console.error("reload method not implement");
        },
        onRescheduleResponseSuccess(act) {
            this.reload();
        },
        /**
         * accept / reject schedule berdasarkan data id selectedjobapplicant
         */
        async rescheduleResponse(id, act) {
            if (await this.requestHandler(this.jobApplicantHttp.rescheduleResponse(id, act))) {
                this.pubSub.trigger(EventNames.RescheduleRespond);
                this.onRescheduleResponseSuccess(act);
            }
        },
    },
});
export const planningModal = defineComponent({
    data() {
        return {
            selectedCrewBoard: null,
            showRenewCrewSignOffModal: false,
            showSwitchCrewModal: false,
        };
    },
    methods: {
        async renewCrewSignOff(crewBoard) {
            this.selectedCrewBoard = crewBoard;
            this.showRenewCrewSignOffModal = true;
        },
        async switchCrew(crewBoard) {
            this.selectedCrewBoard = crewBoard;
            this.showSwitchCrewModal = true;
        },
    },
});
export const userProfilePDF = defineComponent({
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        pdfFilename() {
            return "untitled";
        },
    },
    methods: {
        async generatePDF() {
            this.loading = true;
            if (this.$refs.pdfContent) {
                await html2pdf()
                    .set({
                    margin: 10,
                    filename: `${this.pdfFilename}.pdf`,
                    pagebreak: {
                        mode: ["avoid-all", "css", "legacy"],
                    },
                    html2canvas: {
                        dpi: 192,
                        useCORS: true,
                    },
                    jsPDF: {
                        orientation: "portrait",
                        format: "a4",
                    },
                })
                    .from(this.$refs.pdfContent)
                    .save();
            }
            this.loading = false;
        },
    },
});
export const crewStatus = defineComponent({
    data() {
        return {
            CrewStatus,
        };
    },
    methods: {
        badgeIconClass(status) {
            if (status === CrewStatus.StandBy ||
                status === CrewStatus.StandByOnFleet) {
                return "mdi mdi-calendar-check";
            }
            else if (status === CrewStatus.OnBoard) {
                return "mdi mdi-calendar-account";
            }
            else if (status === CrewStatus.Releiver) {
                return "mdi mdi-swap-horizontal";
            }
            else if (status === CrewStatus.Identifier) {
                return "mdi mdi-account-clock";
            }
            return "";
        },
        badgeColor(status) {
            if (status === CrewStatus.StandBy) {
                return "success";
            }
            else if (status === CrewStatus.StandByOnFleet) {
                return "warning";
            }
            else if (status === CrewStatus.OnBoard) {
                return "primary";
            }
            else if (status === CrewStatus.Releiver) {
                return "warning";
            }
            else if (status === CrewStatus.Identifier) {
                return "default";
            }
            return "";
        },
    },
});
export const jobApplicantStatus = defineComponent({
    data() {
        return {
            JobApplicantStatus,
        };
    },
    methods: {
        isRejected(status) {
            return (status === JobApplicantStatus.Rejected ||
                status === JobApplicantStatus.RescheduleRejected ||
                status === JobApplicantStatus.InterviewRejected);
        },
        isInterview(status) {
            return (status === JobApplicantStatus.Interview ||
                status === JobApplicantStatus.Rescheduled);
        },
        badgeIconClass(status) {
            if (status === JobApplicantStatus.InterviewInvitation ||
                status === JobApplicantStatus.Rescheduled) {
                return "mdi mdi-calendar-clock";
            }
            else if (status === JobApplicantStatus.Interview) {
                return "mdi mdi-calendar-check";
            }
            else if (status === JobApplicantStatus.RescheduleRequest) {
                return "mdi mdi-account-question";
            }
            else if (this.isRejected(status)) {
                return "mdi mdi-close";
            }
            else if (status === JobApplicantStatus.Hired) {
                return "mdi mdi-check-bold";
            }
            else if (status === JobApplicantStatus.RecruitmentRequest) {
                return "mdi mdi-account-clock";
            }
            return "";
        },
        badgeColor(status) {
            if (status === JobApplicantStatus.Interview ||
                status === JobApplicantStatus.InterviewInvitation) {
                return "primary";
            }
            else if (status === JobApplicantStatus.Rescheduled) {
                return "info";
            }
            else if (status === JobApplicantStatus.RescheduleRequest ||
                status === JobApplicantStatus.RecruitmentRequest) {
                return "warning";
            }
            else if (this.isRejected(status)) {
                return "danger";
            }
            else if (status === JobApplicantStatus.Hired) {
                return "success";
            }
            return "";
        },
    },
});
export const selectionSaveModal = defineComponent({
    computed: {
        selectionStore,
    },
    data() {
        return {
            selectionSaveModalTitle: "",
            selectedSelection: {},
            showSelectionSaveModal: false,
            showConfirmDeleteSelectionModal: false,
        };
    },
    methods: {
        async removeSelectionConfirmed() {
            if (this.selectedSelection.type &&
                (await this.selectionStore.remove(this.selectedSelection.type, this.selectedSelection.id, this.selectedSelection.parentId))) {
                this.showConfirmDeleteSelectionModal = false;
            }
        },
        removeSelection(selection) {
            this.selectedSelection = selection;
            this.showConfirmDeleteSelectionModal = true;
        },
        addOrEditSelection(title, selectedSelection = null) {
            if (selectedSelection) {
                this.selectedSelection = selectedSelection;
            }
            this.selectionSaveModalTitle = title;
            this.showSelectionSaveModal = true;
        },
    },
});
export const positionFilter = (filterKey = "position_in") => defineComponent({
    data() {
        return {
            submittedFilter: {},
            filter: {},
            formGroupPosition: null,
        };
    },
    computed: {
        selectionStore,
    },
    mounted() {
        this.selectionStore.fetch();
        this.formGroupPosition = this.$refs.formGroupPosition;
    },
    methods: {
        async resetFilterPositions() {
            var _a, _b, _c;
            this.filter[filterKey] = [];
            for (let i = 0; i != ((_b = (_a = this.submittedFilter[filterKey]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0); i++) {
                const position = this.selectionStore.findById((_c = this.submittedFilter[filterKey]) === null || _c === void 0 ? void 0 : _c[i], this.selectionStore.state.job_position);
                if (position) {
                    this.filter[filterKey].push({
                        value: position.id,
                        text: position.name,
                    });
                }
            }
            await this.$nextTick();
            this.formGroupPosition.$refs.select.initSelectedItem();
        },
    },
});
export const filterHelper = () => defineComponent({
    data() {
        return {
            submittedFilter: {},
            filter: {},
        };
    },
    computed: {
        exceptKeysForFilterCount() {
            return ["search", "page"];
        },
    },
    methods: {
        resetFilterPositions() {
            return;
        },
        reload() {
            return;
        },
        onReset() {
            return;
        },
        compareFilterWithSubmitted() {
            const keys = Object.keys(this.filter);
            for (let i = 0; i != keys.length; i++) {
                if (this.exceptKeysForFilterCount.indexOf(keys[i]) === -1) {
                    this.filter[keys[i]] = this.submittedFilter[keys[i]];
                }
            }
        },
        async resetAll(reload = false) {
            await this.$nextTick();
            this.compareFilterWithSubmitted();
            this.resetFilterPositions();
            this.onReset();
            if (reload) {
                this.reload();
            }
        },
    },
});
export const syncQueryAndData = defineComponent({
    methods: {
        /**
         * panggil saat mounted.
         */
        syncQueryAndData(data, queryName, initDataValue, setQueryValueOnChange = null) {
            var _a;
            if (initDataValue)
                initDataValue(((_a = this.$route.query[queryName]) !== null && _a !== void 0 ? _a : ""));
            // memberi nilai query string dari nilai data
            watch(() => data(), (val) => {
                const query = {
                    ...this.$route.query,
                };
                if (setQueryValueOnChange) {
                    query[queryName] = setQueryValueOnChange(val);
                }
                else {
                    query[queryName] = val;
                }
                this.$router.replace({
                    query,
                });
            }, {
                deep: true,
            });
        },
    },
});
export const selectionDataHelper = defineComponent({
    methods: {
        compareSelectionOrder(a, b) {
            return b.id - a.id;
        },
    },
});
export const selectionDataProp = defineComponent({
    mixins: [selectionDataHelper],
    props: {
        selectionData: {
            type: Object,
        },
    },
    computed: {
        childs() {
            var _a, _b;
            if (!((_a = this.selectionData) === null || _a === void 0 ? void 0 : _a.recursive_childs))
                return [];
            return (_b = this.selectionData) === null || _b === void 0 ? void 0 : _b.recursive_childs.sort(this.compareSelectionOrder);
        },
    },
});
export const baseModal = defineComponent({
    emits: ["update:modelValue"],
    props: {
        title: String,
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: false,
        };
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(val) {
                this.value = val;
            },
        },
        value(val) {
            this.$emit("update:modelValue", val);
        },
    },
});
export const dropdown = defineComponent({
    components: { DropdownItem, Dropdown, DropdownToggler, DropdownMenu },
});
export const actionToolDropdown = defineComponent({
    emits: ["edit", "delete"],
    props: {
        withEdit: {
            type: Boolean,
            default: true,
        },
        withDelete: {
            type: Boolean,
            default: true,
        },
        menuAlign: {
            type: String,
            default: "right",
        },
        editRoute: {
            type: Object,
            default: null,
        },
    },
});
export const uwuLoaderDelay = defineComponent({
    props: {
        loading: {
            type: Boolean,
        },
    },
    emits: ["update:loading"],
    computed: {
        uwuLoaderTimeout: () => 700,
    },
    data() {
        return {
            isLoading: false,
            loadingTimeout: 0,
        };
    },
    watch: {
        loading: {
            immediate: true,
            handler(isLoading) {
                if (!isLoading && this.isLoading) {
                    this.$emit("update:loading", true);
                    clearTimeout(this.loadingTimeout);
                    this.loadingTimeout = setTimeout(() => {
                        this.isLoading = false;
                        this.$emit("update:loading", false);
                    }, this.uwuLoaderTimeout);
                    return;
                }
                this.isLoading = isLoading;
            },
        },
    },
});
export const groupInput = defineComponent({
    props: {
        label: {
            type: String,
        },
        value: {
            required: true,
        },
    },
    computed: {
        randId() {
            return randomString();
        },
    },
});
export const twoStateBindValue = defineComponent({
    props: ["modelValue"],
    emits: ["update:modelValue", "updated"],
    data() {
        return {
            inputValue: "",
        };
    },
    watch: {
        inputValue: {
            deep: true,
            handler(val) {
                this.$emit("update:modelValue", val);
                this.$emit("updated", val);
            },
        },
        modelValue: {
            deep: true,
            immediate: true,
            handler(val) {
                this.inputValue = val;
                this.$emit("updated", val);
            },
        },
    },
});
export const saveAndUpdate = (resourceHttp) => defineComponent({
    mixins: [helperMixin],
    emits: ["saved"],
    computed: {
        updateId() {
            var _a;
            return (_a = this.currentRoute.params) === null || _a === void 0 ? void 0 : _a.id;
        },
        isUpdateState() {
            return this.updateId ? true : false;
        },
        redirectAfterSubmit() {
            return true;
        },
    },
    data() {
        return {
            loading: true,
            /** data from response "read" */
            data: null,
            form: {},
            validationErrors: {},
            submitting: false,
        };
    },
    async mounted() {
        await this.setup();
    },
    methods: {
        async setup() {
            this.loading = true;
            this.form = {};
            this.validationErrors = {};
            this.data = null;
            if (this.isUpdateState) {
                const { response, status } = await resourceHttp.read(this.updateId);
                if (status === 200)
                    this.data = response.data;
            }
            await this.onMounted();
            await this.$nextTick();
            this.loading = false;
            this.setForm();
        },
        beforeSubmit(form) {
        },
        afterSubmit(data) {
            if (process.env.mode !== "production" && !this.redirectAfterSubmit) {
                console.warn("method not implemented");
            }
        },
        async onMounted() {
            if (process.env.mode !== "production") {
                console.warn("method not implemented");
            }
        },
        setForm() {
            if (process.env.mode !== "production") {
                console.warn("method not implemented");
            }
        },
        async submit() {
            var _a;
            if (this.submitting)
                return;
            this.validationErrors = {};
            this.beforeSubmit(this.form);
            try {
                this.submitting = true;
                let http;
                if (this.isUpdateState) {
                    http = await resourceHttp.update(this.form, this.updateId);
                }
                else {
                    http = await resourceHttp.create(this.form);
                }
                if (http.status === 200) {
                    if (this.redirectAfterSubmit) {
                        useRouter().push({
                            name: (_a = this.currentRoute.meta) === null || _a === void 0 ? void 0 : _a.rootName,
                        });
                    }
                    else {
                        this.afterSubmit(http.response.data);
                    }
                    toast("success", this.makeFirstCapital(http.message));
                    this.$emit("saved", http.response.data);
                }
                else if (http.status !== 422) {
                    toast("error", http.message, 0);
                }
                this.submitting = false;
            }
            catch (e) {
                this.submitting = false;
                // entar dibuat agar ditampung di variable global
                // sehingga menampilkan halaman error
                toast("error", e.message, 0);
                throw e;
            }
            this.validationErrors = resourceHttp.validationErrors;
        },
    },
});
export const infinityScrollTable = (resourceHttp, filter) => defineComponent({
    data() {
        return {
            selectedRow: null,
            confirmDeleteModal: false,
            searchInputTimeout: 0,
            spaceBeforeLoadMore: 10,
            loading: true,
            hasMore: true,
            onFetch: false,
            filter: {
                ...filter,
                search: "",
                page: 1,
            },
            rows: [],
            totalAllRows: 0,
            bodyScrollEventIndex: -1,
            withContentBodyScrollEvent: true,
            unwatchSearch: () => {
            },
        };
    },
    computed: {
        contentTool,
        resourceHttp: () => resourceHttp,
        noDataAvailable() {
            return !this.loading && !this.rows.length;
        },
        noResultFound() {
            return !this.rows.length && this.filter.search !== "";
        },
        paginateMethodName() {
            return "paginate";
        },
    },
    async mounted() {
        await this.onMounted();
        this.fetch();
        if (this.withContentBodyScrollEvent) {
            this.bodyScrollEventIndex = onContentBodyScroll((e) => this.handleScroll(e.target));
        }
        this.unwatchSearch = this.contentTool.watch("search", (val) => {
            clearTimeout(this.searchInputTimeout);
            this.searchInputTimeout = setTimeout(() => {
                this.filter.search = val;
                this.hasMore = true;
                this.filter.page = 1;
                this.fetch(true);
            }, 300);
        });
        useRouter().beforeResolve(() => {
            offContentBodyScroll(this.bodyScrollEventIndex);
            this.unwatchSearch();
        });
    },
    methods: {
        async onMounted() {
        },
        async deleteSelectedRow() {
            if (!this.selectedRow)
                return;
            const { status, message } = await resourceHttp.delete(this.selectedRow.id);
            if (status === 200) {
                this.confirmDeleteModal = false;
                this.selectedRow = null;
                this.rows = [];
                this.filter.page = 1;
                this.fetch();
            }
            else {
                toast("error", message, 0);
            }
        },
        showConfirmDelete(d) {
            this.confirmDeleteModal = true;
            this.selectedRow = d;
        },
        loadMore() {
            if (!this.hasMore || this.loading)
                return;
            this.filter.page++;
            this.fetch();
        },
        handleScroll(contentBody) {
            if (contentBody.scrollTop >=
                contentBody.scrollHeight -
                    contentBody.clientHeight -
                    this.spaceBeforeLoadMore) {
                this.loadMore();
            }
        },
        onFetchSuccess(data) {
            return;
        },
        async fetch(reset = false) {
            if (this.onFetch)
                return;
            this.onFetch = true;
            this.loading = true;
            this.filter.search = this.contentTool.state.search;
            const res = await this.resourceHttp[this.paginateMethodName](this.filter);
            this.onFetchSuccess(res);
            const { data, has_more, total, status, message } = res;
            this.rows = !reset
                ? [...this.rows, ...(data !== null && data !== void 0 ? data : [])]
                : data || [];
            this.hasMore = has_more;
            this.totalAllRows = total | 0;
            this.loading = false;
            this.onFetch = false;
            if (status !== 200) {
                toast("error", message !== null && message !== void 0 ? message : trans("layout.error"), 0);
            }
        },
        async reload() {
            var _a, _b;
            this.selectedRow = null;
            (_b = (_a = Layout()) === null || _a === void 0 ? void 0 : _a.contentBody) === null || _b === void 0 ? void 0 : _b.scrollTo({
                top: 0,
            });
            this.filter.page = 1;
            this.fetch(true);
        },
    },
});
export const infinitySrollJobApplicantByStatus = (status_in) => defineComponent({
    mixins: [
        infinityScrollTable(jobApplicantHttp()),
    ],
    beforeMount() {
        this.filter.status_in = status_in;
    },
});
export const authMixin = (type) => defineComponent({
    data() {
        return {
            errorMsg: "",
            loading: false,
            formData: {},
            validationErrors: {},
        };
    },
    computed: {
        authHttp,
        authFacade,
        authStore,
    },
    methods: {
        async submit() {
            if (type === undefined)
                return;
            this.loading = true;
            const { failed, message, validationErrors } = await this.authFacade.attempt(type, this.formData);
            if (failed) {
                this.errorMsg = message;
            }
            this.loading = false;
            this.validationErrors = validationErrors || {};
        },
    },
});
export const helperMixin = defineComponent({
    data() {
        return {
            UserRole,
            RouteNames,
            EventNames,
            requestHandlerLoading: false,
            requestHandlerStatus: undefined,
            validationErrors: {},
        };
    },
    computed: {
        appStore,
        authStore,
        pubSub: createInstance(PubSub),
        http: createInstance(http),
        currentRoute() {
            return this.$route;
        },
    },
    methods: {
        useRouter,
        fullname,
        goTo,
        trans,
        numberFormat,
        makeFirstCapital,
        capitalize,
        replaceWithSpace,
        setPageTitle,
        yearKeyup(e) {
            const value = (e.target.value = e.target.value.replace(/[^0-9]/g, ""));
            if (value.length > String(new Date().getFullYear()).length) {
                e.target.value = value.substring(0, 4);
            }
        },
        formatNotificationCount(count) {
            return count > 99 ? "99+" : count;
        },
        emptyString(val) {
            return val ? val : "-";
        },
        formatDate(val, withTime = false) {
            return formatDate(val, withTime);
        },
        async requestHandler(request) {
            if (this.requestHandlerLoading)
                return null;
            this.requestHandlerLoading = true;
            const res = await request;
            this.requestHandlerLoading = false;
            this.validationErrors = res.validationErrors || {};
            this.requestHandlerStatus = res.status;
            if (res.status !== 200 && res.status !== 422) {
                toast("error", res.message, 0);
            }
            else if (res.status === 200) {
                toast("success", res.message);
                return res;
            }
            return null;
        },
        imageUrlFromFile(file) {
            return URL.createObjectURL(file);
        },
        selectionDataToBaseItem(arrs, otherDataKeys = null) {
            if (!arrs.map)
                return [];
            const items = [];
            for (let i = 0; i < arrs.length; i++) {
                const item = {
                    value: arrs[i].id,
                    text: arrs[i].name,
                };
                if (otherDataKeys) {
                    for (let j = 0; j < otherDataKeys.length; j++) {
                        item[otherDataKeys[j]] = arrs[i][otherDataKeys[j]];
                    }
                }
                items.push(item);
            }
            return items;
        },
        getAge(date) {
            if (!date)
                return "-";
            return moment().diff(date, "years", false);
        },
    },
});
