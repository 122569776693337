import { helperMixin, infinitySrollJobApplicantByStatus, interviewResponse, jobApplicantStatus, rescheduleResponse, } from "@/mixins";
import { defineComponent } from "vue";
import { JobApplicantStatus } from "@/enums";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import ProfileDetailModal from "@/components/JobApplicant/ProfileDetailModal.vue";
export default defineComponent({
    mixins: [
        interviewResponse,
        rescheduleResponse,
        helperMixin,
        jobApplicantStatus,
        infinitySrollJobApplicantByStatus([
            JobApplicantStatus.InterviewInvitation,
            JobApplicantStatus.Interview,
            JobApplicantStatus.InterviewRejected,
            JobApplicantStatus.RescheduleRequest,
            JobApplicantStatus.Rescheduled,
            JobApplicantStatus.RescheduleRejected,
            JobApplicantStatus.Rejected,
            JobApplicantStatus.RecruitmentRequest,
            JobApplicantStatus.Hired,
        ]),
    ],
    components: {
        ActionTableColumn,
        DropdownItem,
        BaseBadge,
        ConfirmModal,
        AvatarImg,
        BaseTableRow,
        BaseTableColumn,
        BaseTable,
        ProfileDetailModal,
    },
    data() {
        return {
            interviewRespondEvent: null,
            rescheduleRespondEvent: null,
            showProfileDetailModal: false,
            selectedJobApplicant: null,
        };
    },
    mounted() {
        this.rescheduleRespondEvent = this.pubSub.on(this.EventNames.RescheduleRespond, () => this.reload());
        this.interviewRespondEvent = this.pubSub.on(this.EventNames.InterviewRespond, () => this.reload());
    },
    beforeUnmount() {
        this.rescheduleRespondEvent ? this.rescheduleRespondEvent() : "";
        this.interviewRespondEvent ? this.interviewRespondEvent() : "";
    },
    methods: {
        showProfileDetail(jobApplicant) {
            this.selectedRow = jobApplicant;
            this.showProfileDetailModal = true;
        },
    },
});
