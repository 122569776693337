import { createInstance } from '@/lib/fn';
import BaseResourceHttp from '@/lib/resourceHttp';
class MenuAccessHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ['paginate', 'delete', 'create'];
    }
    baseURL() {
        return "menu_access";
    }
    async getMenus() {
        var _a;
        const { response } = await this.request("GET", this.url('menus'));
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
}
export default createInstance(MenuAccessHttp);
