import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import { CrewStatus, Readliness } from "@/enums";
import { crewStatus, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
export default defineComponent({
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    components: {
        BaseBadge,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        ActionTableColumn,
        DropdownItem
    },
    mixins: [helperMixin, crewStatus],
    data() {
        return {
            CrewStatus,
            Readliness,
            showSubtite: true
        };
    }
});
